<script setup lang="ts">
import type { Entry } from "contentful";
import {
  isTypeArticle,
  isTypeDownload,
  isTypeProject,
  isTypeReport,
  type TypeArticleSkeleton,
  type TypeDownloadSkeleton,
  type TypeProjectSkeleton,
  type TypeReportSkeleton,
} from "~/types/contentful";
import { hasThemeOrTopic } from "~/lib/article/type-guards.js";

const props = defineProps<{
  savedItem: Entry<TypeArticleSkeleton | TypeDownloadSkeleton | TypeProjectSkeleton | TypeReportSkeleton, "WITHOUT_UNRESOLVABLE_LINKS">;
}>();

const type = computed(() => {
  if (isTypeArticle(props.savedItem)) {
    return props.savedItem.fields.articleType?.fields.title;
  } else if (isTypeReport(props.savedItem)) {
    return props.savedItem.sys.contentType.sys.id;
  }

  return undefined;
});

const themeOrTopic = computed(() => {
  if (hasThemeOrTopic(props.savedItem)) {
    return props.savedItem.fields.themeOrTopic;
  }

  return undefined;
});
</script>

<template>
  <TextBadge
    v-if="type"
    class="me-2 h-[22px] bg-white"
  >
    {{ type }}
  </TextBadge>
  <ArticleTopic
    v-if="
      themeOrTopic
        && ((Array.isArray(themeOrTopic) && themeOrTopic?.length < 3)
          || isTypeDownload(savedItem)
          || isTypeProject(savedItem))
    "
    :theme-or-topics="
      Array.isArray(themeOrTopic)
        ? themeOrTopic.filter(
          (themeOrTopic): themeOrTopic is NonNullable<typeof themeOrTopic> => !!themeOrTopic,
        )
        : [themeOrTopic]
    "
    bg-colour="bg-white"
  />
  <TextBadge
    v-else
    class="mb-[6px] me-2 h-[22px] bg-white"
  >
    Multiple ESG Topics
  </TextBadge>
</template>
