<script setup lang="ts">
import { formatDate, formatTime } from "~/lib/date-time";
import type { TypeRepeater } from "~/types/TypeRepeater";
import type { TypeDownload, TypeEvent } from "~/types/contentful";
import { accessLevel } from "~/lib/downloads";

const props = defineProps<{
  event: TypeEvent<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();

const eventDateStart = computed(
  () => new Date(props.event.fields.startdate ?? ""),
);

const eventDateEnd = computed(() => new Date(props.event.fields.endDate ?? ""));

const isOneDayEvent = computed(
  () => eventDateStart.value.getDate() === eventDateEnd.value.getDate(),
);

const openModal = reactive<Record<string, boolean>>({});

const user = useUser();
</script>

<template>
  <div class="rounded border border-ui-grey2 p-4">
    <h5 class="font-bold">
      Key Information
    </h5>
    <table class="event-info mb-2 w-full py-[12px] align-top">
      <tr>
        <td class="text-sm font-bold">
          Date:
        </td>
        <td class="pl-[10px] text-right">
          {{ formatDate(eventDateStart, !isOneDayEvent) }}
          <template v-if="!isOneDayEvent">
            - {{ formatDate(eventDateEnd) }}
          </template>
        </td>
      </tr>
      <tr>
        <td class="text-sm font-bold">
          Time:
        </td>
        <td class="pl-[10px] text-right">
          {{ formatTime(eventDateStart) }}
          -
          {{ formatTime(eventDateEnd) }}
          <span class="whitespace-nowrap">{{
            event.fields.timezone || ""
          }}</span>
        </td>
      </tr>

      <tr>
        <td class="text-sm font-bold">
          Location:
        </td>
        <td class="pl-[10px] text-right">
          {{ event.fields.location }}
        </td>
      </tr>
      <tr v-if="event.fields.addressText">
        <td class="text-sm font-bold">
          Address:
        </td>
        <td class="pl-[10px] text-right">
          {{ event.fields.addressText }}
        </td>
      </tr>
    </table>
    <div v-if="event.fields.addressText">
      <iframe
        width="100%"
        height="160"
        :src="
          'https://www.google.com/maps?q='
            + event.fields.addressText
            + '&output=embed'
        "
      />
    </div>
    <div v-if="event.fields.button">
      <hr class="hr-divider" />
      <hr class="hr-divider" />
      <hr class="hr-divider" />
      <NuxtLink
        v-for="button in (event.fields.button as unknown as TypeRepeater[])"
        :key="button.id"
        class="btn mt-3 w-full"
        :to="button.value"
        target="_blank"
      >
        {{ button.key }}
      </NuxtLink>
    </div>

    <div v-if="event.fields.video && event.fields.video">
      <hr class="hr-divider" />
      <hr class="hr-divider" />
      <hr class="hr-divider" />

      <template
        v-for="video in event.fields.video"
        :key="video?.sys.id"
      >
        <LazyOtherModal
          v-model="openModal[video?.sys.id ?? '']"
          :vimeo-video-url="video?.fields.url"
        />

        <button
          v-if="
            accessLevel(
              user,
              video as TypeDownload<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
            ) === 'full'
          "
          class="btn mt-3 w-full"
          @click="openModal[video?.sys.id ?? ''] = true"
        >
          {{ video?.fields.shortTitle }}
        </button>

        <NuxtLink
          v-else-if="
            accessLevel(
              user,
              video as TypeDownload<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
            ) === 'locked'
          "
          class="btn mt-3 w-full"
          to="/login"
        >
          {{ video?.fields.shortTitle }}
          <NuxtIcon
            class="ml-2"
            name="fairr:lock"
            size="16"
          />
        </NuxtLink>
      </template>
    </div>
  </div>
</template>
