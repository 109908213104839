<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";

const user = useUser();
const config = useContentConfig();

withDefaults(
  defineProps<{
    level?: ("Member" | "Journalist" | "Public")[];
    showContent?: boolean;
    showPush?: boolean;
  }>(),
  { level: () => ["Member"], showContent: false, showPush: false },
);
</script>

<template>
  <slot
    v-if="level.includes('Public') || (user && level.includes('Member'))"
  />
  <div
    v-else
    class="relative"
  >
    <div
      :class="{
        'bg-level-standard': !showContent,
        'max-h-[400px] min-h-[400px] overflow-y-hidden': showPush,
      }"
    >
      <slot v-if="showContent" />
    </div>
    <div
      class="absolute left-0 top-0 z-10 flex size-full items-center justify-center"
    >
      <div
        class="absolute left-[-8px] top-[-8px] size-[calc(100%+16px)] backdrop-blur-sm"
      />
      <div
        v-if="showPush"
        class="absolute w-[476px] rounded-sm border border-ui-grey2 bg-white p-4 text-center shadow-[0_0_40px_0] shadow-black/20 max-sm:left-3 max-sm:h-auto max-sm:w-[calc(100%-3px)] md:px-5"
      >
        <h4 class="mb-3">
          <slot name="push-title">
            Members-only Content
          </slot>
        </h4>
        <p>
          <slot name="push-message">
            <RichTextRenderer
              :document="config?.globalSignupBoxMessage"
            />
          </slot>
        </p>
        <div
          class="mt-3 flex justify-center gap-3 max-sm:flex-wrap max-sm:gap-2"
        >
          <slot name="push-buttons">
            <NuxtLink
              class="btn"
              to="/login"
            >
              Login
            </NuxtLink>
            <NuxtLink
              class="btn"
              to="/signup"
            >
              Sign Up
            </NuxtLink>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn {
  @apply w-[45%] max-w-[126px] justify-center max-sm:w-full max-sm:max-w-full;
}
</style>
