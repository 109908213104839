<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockContact } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers";

defineProps<{
  fields: TypeBlockContact<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const sent = ref(false);
</script>

<template>
  <UiContainer class="py-4">
    <HeaderBlockBreadcrumbs class="mb-4 text-dark-blue-grey-two" />
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-9">
      <div>
        <h1 class="mb-3">
          {{ fields.title }}
        </h1>
        <RichTextRenderer
          :document="fields.copy"
          :node-renderers="nodeRenderers"
        />
      </div>
      <div
        v-if="fields.formEndpoint"
        class="flex items-center bg-ui-grey4 px-7 py-5"
      >
        <FormContact
          v-if="!sent"
          class="w-full"
          :form-endpoint="fields.formEndpoint"
          @sent="sent = true"
        />
        <RichTextRenderer
          v-else
          :document="fields.sentCopy"
          :node-renderers="nodeRenderers"
        />
      </div>
    </div>
  </UiContainer>
</template>

<style lang="scss" scoped>
:deep(table) {
  @apply my-3 w-full border-b border-t border-b-ui-grey2 border-t-ui-grey2 text-left;

  th,
  td {
    @apply w-1/2 align-top;
  }

  th {
    @apply text-sm;
  }

  td {
    @apply pb-4 align-top;

    a {
      @apply text-base;
    }
  }

  p {
    @apply mb-0 w-2/3;
  }
}

:deep(h5) {
  @apply text-sm;
}

:deep(a) {
  @apply text-sm underline underline-offset-4;
}
</style>
