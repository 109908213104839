<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers.js";
import type { TypeBlockImageText } from "~/types/contentful";
import type { TypeButton } from "~/types/TypeButton";

const props = defineProps<{
  fields: TypeBlockImageText<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"] & { button: TypeButton[] };
}>();

const colWidth = computed(() => {
  if (props.fields.fullWidth) {
    return "col-span-12";
  } else {
    return props.fields.image?.fields.file ? "lg:col-span-6" : "lg:col-span-8";
  }
});
</script>

<template>
  <UiContainer class="py-4 md:py-6">
    <div class="grid grid-cols-12 gap-4">
      <div
        v-if="fields.displaySectionTitle"
        class="col-span-12"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
      <div
        v-if="fields.text"
        class="col-span-12"
        :class="colWidth"
      >
        <div class="wysiwyg mb-4">
          <RichTextRenderer
            :document="fields.text"
            :node-renderers="nodeRenderers"
          />
        </div>
        <NuxtLink
          v-for="button in fields.button"
          :key="button.title"
          class="btn mr-3"
          :class="'btn--' + button.type"
          :to="button.url"
        >
          {{ button.title }}
        </NuxtLink>
        <div v-if="fields.downloads">
          <UiDownloadLink
            v-for="download in fields.downloads.filter(
              (download): download is NonNullable<typeof download> =>
                !!download,
            )"
            :key="download.sys.id"
            class-list="btn mt-3"
            :download="download"
          >
            {{ download.fields.shortTitle }}
            <NuxtIcon
              class="ml-2"
              name="fairr:download"
              size="24"
            />
            <template #locked>
              {{ download.fields.shortTitle }}
              <NuxtIcon
                class="ml-2"
                name="fairr:lock"
                size="24"
              />
            </template>
          </UiDownloadLink>
        </div>
      </div>
      <div
        v-if="fields.image?.fields.file"
        class="col-span-12"
        :class="{
          'lg:col-span-5 lg:col-start-8': fields.imageSize === 'medium',
          'lg:col-span-6': fields.imageSize === 'large',
        }"
      >
        <NuxtImg
          class="w-full"
          :src="fields.image.fields.file.url"
          :alt="fields.image.fields.title"
          :width="975"
          loading="lazy"
        />
      </div>
    </div>
  </UiContainer>
</template>
