<script setup lang="ts">
import type { EntryCollection, EntrySys } from "contentful";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~~/lib/node-renderers.js";
import type {
  TypeCardBlockLogo,
  TypeCompanySkeleton,
  TypeNetworkMemberSkeleton,
} from "~/types/contentful";

const props = defineProps<{
  fields: TypeCardBlockLogo<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  sys: EntrySys;
}>();

const input = ref("");

const filterHqs = ref<string[]>([]);
const filters = reactive([filterHqs.value]);

const sortOptions = [
  "A - Z",
  "Z - A",
  "AUM High - Low",
  "AUM Low - High",
  "Joined Newest - Oldest",
  "Joined Oldest - Newest",
];
const sort = ref(sortOptions[0]);

const route = useRoute();

const currentPage = ref(Number(route.query[`page_${props.sys.id}`]) || 1);
const perPage = 18;
const page = computed(
  () => Number(route.query[`page_${props.sys.id}`]) || 1,
);

const { execute: fetchCompanies, data: companies } = await useLazyFetch<
  EntryCollection<TypeCompanySkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/companies", {
  query: {
    projectIds: props.fields.filter?.sys.id,
    hq: filterHqs,
    input,
    sort,
    page,
    perPage,
  },
  immediate: false,
  watch: false,
});

const { execute: fetchCompaniesFilter, data: companiesFilter }
  = await useLazyFetch("/api/filter/companies", {
    immediate: false,
  });

if (props.fields.companyType === "Company") {
  fetchCompanies();

  fetchCompaniesFilter();
}

const { execute: fetchNetworkMembers, data: networkMembers }
  = await useLazyFetch<
    EntryCollection<
      TypeNetworkMemberSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      "en-GB"
    >
  >("/api/network-members", {
    query: {
      projectIds: props.fields.filter?.sys.id,
      hq: filterHqs,
      input,
      sort,
      page,
      perPage,
    },
    immediate: false,
    watch: false,
  });

const { execute: fetchNetworkMembersFilter, data: networkMembersFilter }
  = await useLazyFetch("/api/filter/network-members", { immediate: false });

if (props.fields.companyType === "Network Member") {
  fetchNetworkMembers();

  fetchNetworkMembersFilter();
}

watch([filters, input, sort, page], () => {
  if (!props.fields.company) {
    if (props.fields.companyType === "Network Member") {
      fetchNetworkMembers();
    }

    if (props.fields.companyType === "Company") {
      fetchCompanies();
    }
  }
});

const logoList = computed(() =>
  props.fields.company
    ? paginate().items
    : companies.value
      ? companies.value.items
      : networkMembers.value
        ? networkMembers.value.items
        : [],
);

const paginationTotal = computed(() => {
  return (
    props.fields.company?.length
    ?? companies.value?.total
    ?? networkMembers.value?.total
    ?? 0
  );
});

watch(currentPage, async (page) => {
  if (props.fields.company) {
    paginate();
  }
  if (!route.hash) {
    await navigateTo({ query: { [`page_${props.sys.id}`]: page } });
  }
});

watch([filters, input, sort], () => {
  currentPage.value = 1;
});

const paginate = () => {
  const offset = perPage * (currentPage.value - 1);
  const totalPages = Math.ceil((props.fields.company?.length ?? 0) / perPage);
  const paginatedItems
    = props.fields.company
      ?.filter((company): company is NonNullable<typeof company> => !!company)
      .slice(offset, perPage * currentPage.value) ?? [];

  return {
    previousPage: currentPage.value - 1 ? currentPage.value - 1 : null,
    nextPage: totalPages > currentPage.value ? currentPage.value + 1 : null,
    total: props.fields.company?.length,
    totalPages,
    items: paginatedItems,
  };
};

// Compute filter panel blocks for displaying filter options
const filterPanelBlocks = computed(() =>
  companiesFilter.value
    ? [companiesFilter.value.hq]
    : networkMembersFilter.value
      ? [networkMembersFilter.value.hq]
      : [],
);

// Handle filter change event
const onFilterChange = (checked: boolean, id: string, blockIndex: number) => {
  if (checked) {
    filters[blockIndex]?.push(id);
  } else {
    filters[blockIndex]?.splice(filterHqs.value.indexOf(id), 1);
  }
};
</script>

<template>
  <UiContainer class="py-4 md:py-6">
    <div class="flex flex-wrap gap-y-4">
      <div
        v-if="props.fields.displaySectionTitle"
        class="w-full"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>

      <div
        v-if="fields.content"
        class="wysiwyg lg:w-1/2"
      >
        <RichTextRenderer
          :document="fields.content"
          :node-renderers="nodeRenderers"
        />
      </div>

      <div
        v-if="!fields.company && !fields.filter"
        class="mt-2 flex w-full flex-col justify-between gap-2 md:mt-4 md:flex-row"
      >
        <div
          class="mb-3 flex h-5 w-full items-center rounded-sm border border-ui-grey2 md:mb-0 md:w-[70%] md:max-w-[700px]"
        >
          <NuxtIcon
            class="mx-2"
            name="fairr:search"
            size="24"
          />
          <input
            v-model="input"
            class="w-full border-0"
            type="text"
            placeholder="Search for..."
            @input="currentPage = 1"
          />
        </div>
        <div class="flex gap-2">
          <UiDropdown
            label="Sort by"
            :options="sortOptions"
            :selected-option="sortOptions[0]"
            @select="(value) => (sort = value)"
          />
          <UiFilterPanel
            :titles="['HQ']"
            :blocks="filterPanelBlocks"
            scroll-on-open
            @on-filter-change="onFilterChange"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-x-4 gap-y-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        <CardBlockLogoItem
          v-for="item in logoList"
          :key="item?.sys.id"
          :item="item"
        />
      </div>

      <ClientOnly>
        <vue-awesome-paginate
          v-if="paginationTotal > 18"
          v-model="currentPage"
          :total-items="paginationTotal"
          :items-per-page="perPage"
          :max-pages-shown="4"
          class="pagination-container"
          type="link"
          :link-url="`?page_${sys.id}=[page]`"
        >
          <template #prev-button>
            prev
          </template>
          <template #next-button>
            next
          </template>
        </vue-awesome-paginate>
      </ClientOnly>

      <div
        v-if="fields.disclaimer"
        class="m-auto text-center !text-xs !text-dark-blue-grey-two/50 lg:w-2/3"
      >
        <RichTextRenderer
          :document="fields.disclaimer"
          :node-renderers="nodeRenderers"
        />
      </div>
    </div>
  </UiContainer>
</template>
