<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { Block, Inline, Text } from "@contentful/rich-text-types";
import nodeRenderers from "~/lib/node-renderers.js";
import { formatDate } from "~/lib/date-time";
import type { TypeReport } from "~/types/contentful";

const props = defineProps<{
  report: TypeReport<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();

const contentStripped = computed(() => {
  const document = props.report.fields.content;

  // remove empty paragraphs
  const content
    = document?.content.filter(
      (node) =>
        !(
          Array.isArray(node.content)
          && node.content.length === 1
          && node.content[0]
          && "value" in node.content[0]
          && node.content[0].value.trim() === ""
        ),
    ) ?? [];

  // remove empty text nodes inside embedded entries
  content.forEach((node) => {
    node.content = node.content.filter((node) => node.nodeType !== "text" || node.value);
  });

  // set embedded entries as TopLevelBlock(s), like a BlockRight not nested in a Paragraph
  const newContent: (Block | Inline | Text)[] = [];
  content.forEach((node) => {
    if (node.content.length === 1 && node.content[0] && node.content[0].nodeType !== "text") {
      newContent.push(node.content[0]);
    } else {
      newContent.push(node);
    }
  });
  return newContent;
});
</script>

<template>
  <div class="article article--report">
    <HeaderReport
      v-if="report.fields.title"
      :report="report"
      :fields="report.fields"
    />

    <UiContainer>
      <div class="article__body grid grid-cols-12 gap-4 py-4 lg:py-6">
        <div class="col-span-12 pt-1 lg:col-span-2">
          <div v-if="report.fields.date">
            {{ formatDate(new Date(report.fields.date)) }}
          </div>

          <ArticleAuthor
            :authors="report.fields.author?.filter((a): a is NonNullable<typeof a> => !!a)"
          />

          <ArticleTopic
            v-if="report.fields.themeOrTopic"
            :theme-or-topics="[report.fields.themeOrTopic]"
          />

          <div class="mt-2">
            <UiSaveButtonIcon
              :entry="report"
              type="save"
              colour="navy"
            />
          </div>
        </div>

        <div
          v-if="
            report.fields.content
              && report.fields.content.content
              && report.fields.content.content.length > 0
          "
          class="col-span-12 lg:col-span-6"
        >
          <RichTextRenderer
            :document="{ content: contentStripped, nodeType: 'document' }"
            :node-renderers="nodeRenderers"
          />
        </div>

        <div class="col-span-12 lg:col-span-3 lg:col-start-10">
          <ReportRelated
            v-if="report.fields.themeOrTopic || report.fields.relatedContent?.length"
            :article-id="report.sys.id"
            :explicit-ids="
              report.fields.relatedContent?.length
                ? report.fields.relatedContent
                  .map((item) => item?.sys.id)
                  .filter((item): item is NonNullable<typeof item> => !!item)
                : []
            "
            :theme-or-topic-ids="
              report.fields.themeOrTopic ? [report.fields.themeOrTopic.sys.id] : []
            "
          />
        </div>
      </div>
    </UiContainer>
  </div>
</template>
