<script setup lang="ts">
import { formatDate } from "~/lib/date-time";
import type { TypeDownload } from "~/types/contentful";

const props = defineProps<{
  download: TypeDownload<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
  duration?: number;
  showDate?: boolean;
}>();

const videoTag = computed(() => props.download.fields.videoType?.fields.title);
</script>

<template>
  <div class="flex flex-wrap items-center gap-3 text-dark-blue-grey-two">
    <TextBadge v-if="videoTag">
      {{ videoTag }}
    </TextBadge>

    <UiThemeBadge :theme-or-topic="download.fields.themeOrTopic" />

    <div class="text-white opacity-60">
      <span v-if="duration">
        <NuxtIcon name="fairr:time" />
        {{ Math.round(duration / 60) }} mins
        <span class="mx-2">|</span>
      </span>
      {{ showDate ? formatDate(new Date(download.fields.date)) : '' }}
    </div>
  </div>
</template>
