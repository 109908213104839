<script setup lang="ts">
const slots = useSlots();

const tabTitles = ref<string[]>([]);

const selectedTitle = ref("");

if (slots.default) {
  tabTitles.value = slots.default({
    selectedTitle: selectedTitle.value,
  }).map((tab: VNode) => tab.props?.title);
}

if (tabTitles.value[0]) {
  selectedTitle.value = tabTitles.value[0];
}

const emit = defineEmits(["select"]);

const onSelect = (title: string) => {
  selectedTitle.value = title;

  emit("select", title);
};
</script>

<template>
  <div>
    <UiContainer class="border-y border-y-ui-grey2 bg-ui-grey4">
      <nav class="flex items-center">
        <div
          v-for="title in tabTitles"
          :key="title"
          class="cursor-pointer p-3 text-sm font-semibold first-of-type:-ml-3"
          :class="{ 'text-light-royal-blue': selectedTitle === title }"
          @click="() => onSelect(title)"
        >
          {{ title }}
        </div>
      </nav>
    </UiContainer>
    <slot :selected-title="selectedTitle" />
  </div>
</template>
