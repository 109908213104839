<!-- eslint-disable-next-line vue/max-lines-per-block -->
<script setup lang="ts">
import type { TypeToolCompanyRanking } from "~/types/contentful";
import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed.js";
import type { TypePdCompany } from "~/types/csv/TypePd";
import type { TypePpiCompany } from "~/types/csv/TypePpi";
import type { TypeSpCompany } from "~/types/csv/TypeSp";
import type { TypeStCompany } from "~/types/csv/TypeSt";
import type { TypeChartDataLayer } from "~/types/TypeChartDataLayer";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import { updateFiltersChecked, filterArray } from "~/lib/filter";
import {
  isCompanyKey,
  isProteinDiversification,
  isProteinProducerIndex,
  isSeafoodTraceability,
  isSustainableProteins,
  isWorkingConditions,
} from "~/lib/company-profile/type-guards.js";
import type { TypeWcCompany } from "~/types/csv/TypeWc";
import type { TypeChartCompany } from "~/types/TypeChartCompany";
import { theme } from "#tailwind-config";

const props = defineProps<{
  fields: TypeToolCompanyRanking<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"];
}>();

const user = useUser();

const { data: dataSourceParsed } = await useLazyFetch<TypeDataSourceParsed>(
  "/api/data-source",
  {
    query: {
      id: props.fields.dataSource?.sys.id,
      project: props.fields.dataSource?.fields.project?.sys.id,
    },
    server: false,
  },
);

const allDataSourcesParsed = computed(() => {
  let dataSource: typeof dataSourceParsed.value | undefined
    = dataSourceParsed.value;

  const dataSources = [];

  if (dataSource) {
    do {
      dataSources.push(dataSource);
    } while ((dataSource = dataSource.previousDataSource));
  }

  return dataSources;
});

const chartYears = computed(() =>
  allDataSourcesParsed.value.map(
    (dataSourceParsed) => dataSourceParsed.content.fields.yearOrPhase,
  ),
);

const yearSelectedIndex = ref(0);

const yearSelected = computed(
  () => chartYears.value[yearSelectedIndex.value] ?? "",
);

const dataSourceByYear = computed(() =>
  allDataSourcesParsed.value.find(
    (dataSourceParsed) =>
      dataSourceParsed.content.fields.yearOrPhase === yearSelected.value,
  ),
);

const regions = computed(() =>
  [
    ...new Set(
      dataSourceByYear.value?.data?.map((row) =>
        "Region" in row ? row.Region : "",
      ),
    ),
  ].sort(),
);

const companyTypes = computed(() =>
  [
    ...new Set(
      dataSourceByYear.value?.data?.map((row) =>
        "Company type" in row ? row["Company type"] : "",
      ),
    ),
  ]
    .filter((type) => type !== "Multiple")
    .sort(),
);

const companyNames = computed(() => [
  ...new Set(
    dataSourceByYear.value?.data
      ?.map((company) => company.Name)
      .filter(Boolean),
  ),
]);

const proteins = computed(() =>
  [
    ...new Set(
      dataSourceByYear.value?.data?.map((row) =>
        "Main Protein Category" in row ? row["Main Protein Category"] : "",
      ),
    ),
  ].sort(),
);

const filterPanelBlocks = computed<string[][]>(() => [
  [...regions.value.filter((val): val is NonNullable<typeof val> => !!val)],
  [
    ...companyTypes.value.filter(
      (val): val is NonNullable<typeof val> => !!val,
    ),
  ],
  [...proteins.value.filter((val): val is NonNullable<typeof val> => !!val)],
  [
    ...companyNames.value.filter(
      (val): val is NonNullable<typeof val> => !!val,
    ),
  ],
]);

const filterTitles = ["Regions", "Company Types", "Proteins", "Companies"];
const filterFields = [
  "Region",
  "Company type",
  "Main Protein Category",
  "Name",
];

const filtersChecked = ref<{ field: string; id: string }[]>([]);

const xValues = computed(() => {
  const grading = Object.keys(dataSourceParsed.value?.scoring?.grading ?? {});
  if (
    isSustainableProteins(dataSourceParsed.value)
    || isProteinDiversification(dataSourceParsed.value)
  )
    return grading;
  return grading.filter((key) => key === "Risk Score" || key.endsWith("F"));
});
const xSelected = ref("");
const x2Selected = ref("");

watch(dataSourceParsed, () => (xSelected.value = xValues.value[0] ?? ""));

const mainX = computed(() =>
  x2Selected.value ? x2Selected.value : xSelected.value,
);

const onFilterChange = (checked: boolean, id: string, index: number) => {
  const field = filterFields[index] ?? "";

  updateFiltersChecked(filtersChecked.value, field, checked, id);

  if (checked) {
    chartDataLayer.filter = {
      group: field,
      value: id,
    };
    pushDataLayerEvent("evChartFilter", chartDataLayer);
  }
};

const onYear = (value: string) => {
  yearSelectedIndex.value = chartYears.value.indexOf(value);

  chartDataLayer.year = value;
  pushDataLayerEvent("evChartYearSelected", chartDataLayer);
};

const sortDesc = ref(true);

const onSort = (value: string) => {
  sortDesc.value = value === "High to Low";

  chartDataLayer.orderBy = value;
  pushDataLayerEvent("evChartOrderSelected", chartDataLayer);
};

const showPillars = ref(false);
const pillarSelected = ref("Commitment");

const onPillars = (value: boolean) => {
  showPillars.value = value;

  if (value) {
    x2Selected.value = "";
  }

  chartDataLayer.pillars = value;
  chartDataLayer.pillar = pillarSelected.value;
  pushDataLayerEvent("evChartPillarsSelected", chartDataLayer);
};

const onPillar = (value: string) => {
  pillarSelected.value = value;
  chartDataLayer.pillar = pillarSelected.value;
  pushDataLayerEvent("evChartPillarSelected", chartDataLayer);
};

const pillars = computed(() => dataSourceParsed.value?.scoring?.pillars.map((pillar) => ({
  key: pillar.key.charAt(0).toUpperCase() + pillar.key.slice(1),
  colour: pillar.resultColour,
  maxResult: pillar.maxResult,
})));

const sortedCompaniesDesc = computed(() => {
  const array = [...(dataSourceByYear.value?.data ?? [])].sort((a, b) => {
    const kpi = mainX.value;

    if (!isCompanyKey(kpi, a) || !isCompanyKey(kpi, b)) {
      return 0;
    }

    let x, y;

    if (showPillars.value) {
      x = Number(a[`${kpi} ${pillarSelected.value}`]);
      y = Number(b[`${kpi} ${pillarSelected.value}`]);
    } else {
      x = Number(a[kpi]);
      y = Number(b[kpi]);
    }

    return x < y ? 1 : x > y ? -1 : 0;
  });

  if (xSelected.value === "Risk Score") {
    return array.sort((a, b) => Number(a.Ranking) - Number(b.Ranking));
  } else {
    return array;
  }
});

const sortedCompanies = computed(() =>
  sortDesc.value
    ? sortedCompaniesDesc.value
    : [...sortedCompaniesDesc.value].reverse(),
);

const filteredCompanies = computed(() => {
  if (filtersChecked.value.length === 0) return sortedCompanies.value;
  if (sortedCompanies.value.length) {
    const regionFilters = filtersChecked.value.filter(
      (filter) => filter.field === "Region",
    );
    const filteredByRegion = filterArray(sortedCompanies.value, regionFilters);
    const typeFilters = filtersChecked.value.filter(
      (filter) => filter.field === "Company type",
    );
    const filteredByType = filterArray(filteredByRegion, typeFilters);
    const nameFilters = filtersChecked.value.filter(
      (filter) => filter.field === "Name",
    );
    const filteredByName = filterArray(filteredByType, nameFilters);
    const proteinFilters = filtersChecked.value.filter(
      (filter) => filter.field === "Main Protein Category",
    );
    const filteredByProtein = filterArray(filteredByName, proteinFilters);
    return filteredByProtein;
  }
  return [];
});

const outlookCodes = computed(() =>
  xValues.value.map((kpi: string) =>
    kpi === "Total Score" ? "Overall Outlook" : kpi + " Outlook",
  ),
);
const selectedOutlook = computed(() =>
  yearSelectedIndex.value !== 0
    ? undefined
    : outlookCodes.value[xValues.value.indexOf(xSelected.value)],
);

const chartCompanies = computed<TypeChartCompany[]>(() => [
  ...new Set(
    filteredCompanies.value
      .filter(
        (company): company is TypePpiCompany | TypeSpCompany | TypeStCompany =>
          isProteinProducerIndex(dataSourceParsed.value)
          || isSustainableProteins(dataSourceParsed.value)
          || isSeafoodTraceability(dataSourceParsed.value),
      )
      .map((row) => {
        const score = isCompanyKey(mainX.value, row)
          ? Number(row[mainX.value])
          : 0;

        let cumulative = 0;

        return {
          name: "Name Short" in row ? row["Name Short"] : "Company Name" in row ? row["Company Name"] : row.Name,
          values: !showPillars.value
            ? [
                {
                  from: 0,
                  to: Math.round(score),
                  field: mainX.value,
                  colour: dataSourceParsed.value?.scoring?.grading[mainX.value]
                    ?.toReversed()
                    .find((grade) => Math.round(score) >= grade.min && Math.round(score) <= grade.max)
                    ?.colour ?? "",
                  outlook: isSustainableProteins(dataSourceParsed.value)
                    && isCompanyKey(selectedOutlook.value, row)
                    ? row[selectedOutlook.value]
                    : undefined,
                },
              ]
            : pillars.value
              ?.filter((pillar) => pillarSelected.value === "All" || [pillarSelected.value].includes(pillar.key))
              .map((pillar) => {
                const key = `${mainX.value} ${pillar.key}`;
                const from = cumulative;
                cumulative = cumulative + Number(isCompanyKey(key, row) ? row[key] : 0);
                const to = cumulative;

                return {
                  from,
                  to,
                  field: key,
                  colour: pillar.colour,
                };
              }),
          csv: row,
        };
      }),
  ),
]);

const chartBgStrips = computed(() =>
  showPillars.value
    ? dataSourceParsed.value?.scoring?.grading[xSelected.value]
      ?.filter((grade) => grade.max - grade.min > 1)
      .toReversed().map((grade) => ({ ...grade, colour: theme.colors["ui-grey2"] }))
    : dataSourceParsed.value?.scoring?.grading[xSelected.value]
      ?.filter((grade) => grade.max - grade.min > 1)
      .toReversed(),
);

const chartMaxScore = computed(
  () =>
    dataSourceParsed.value?.scoring?.grading[xSelected.value]?.reduce(
      (max, grade) => (grade.max > max ? grade.max : max),
      0,
    ) ?? 0,
);

const xValuesTitles = computed(() =>
  xValues.value.map((code) => {
    const item = dataSourceParsed.value?.methodology?.find(
      (item) => item.Code === code,
    );
    return item?.Title ? item.Title : code;
  }),
);

const subKpis = computed(() => {
  const kpi = { Code: "", Question: "" };
  if (!xSelected.value || xSelected.value === "Risk Score") return [kpi];
  const kpiBase = xSelected.value.slice(0, -1); // GHGF -> GHG, DEFF -> DEF
  return (
    dataSourceParsed.value?.methodology?.filter(
      (item) => item.Code?.includes(kpiBase) && item.Score === "100",
    ) ?? [kpi]
  );
});

const subKpisCodes = computed(() => subKpis.value.map((kpi) => kpi.Code ?? ""));
const subKpisTitles = computed(() =>
  subKpis.value.map((kpi) => kpi.Question ?? ""),
);

const onXSelect = (x: string, index?: number) => {
  xSelected.value = typeof index !== "undefined" ? (xValues.value[index] ?? "") : x;

  x2Selected.value = "";

  chartDataLayer.xValue = typeof index !== "undefined" ? xValuesTitles.value[index] : x;
  pushDataLayerEvent("evChartXSelected", chartDataLayer);

  // COMMENTED - do not modify the output of a computed ref
  //
  /* Change Pillars MaxResults based on selected KPI */
  // if (x !== "Risk Score" && isProteinProducerIndex(dataSourceParsed.value)) {
  //   const kpi = dataSourceParsed.value.breakdown?.find((kpi) => kpi.code === x.replace(/F/g, ""));
  //   pillars.value?.forEach((pillar) => {
  //     const kPillar = kpi?.pillars.find((kPillar) => kPillar.key === pillar.key.toLowerCase());
  //     return pillar.maxResult = kPillar?.maxScore;
  //   });
  // } else {
  //   pillars.value?.forEach((pillar) => {
  //     const rsPillar = dataSourceParsed.value?.scoring?.pillars.find((rsPillar) => rsPillar.key === pillar.key.toLowerCase());
  //     return pillar.maxResult = rsPillar?.maxResult;
  //   });
  // }
};

const onX2Select = (x: string, index?: number) => {
  x2Selected.value = typeof index !== "undefined" ? (subKpisCodes.value[index] ?? "") : x;

  if (x2Selected.value) {
    showPillars.value = false;
  }

  chartDataLayer.x2Value = typeof index !== "undefined" ? subKpisTitles.value[index] : x;
  pushDataLayerEvent("evChartX2Selected", chartDataLayer);
};

const onWalk = (event: { index: number; minimized: boolean }) => {
  if (event.index === 1) {
    onPillars(true);
  }

  forceHoverIndex.value = event.index === 3 ? 0 : -1;
};

const chartDataLayer = reactive<TypeChartDataLayer>({
  name: props.fields.name,
  project: dataSourceParsed.value?.content.fields.project?.fields.name ?? "",
});

const walkthroughSteps = [
  {
    headline: "See what's new for Coller FAIRR Protein Producer Index 2024",
    text: "We have designed a Supply Chain Analysis tool, which highlights the relationship between downstream companies (retailers, food manufacturers and restaurants) and their upstream suppliers (protein producers).",
    elementIds: "tabSupplyChainAnalysis",
    noScroll: true,
    buttonText: "Go to Supply Chain Analysis",
    buttonLink: "/tools/protein-producer-index#supply-chain-analysis/analysis",
  },
];

const forceHoverIndex = ref(-1);
</script>

<template>
  <section class="relative py-4 md:py-6">
    <UiContainer v-if="dataSourceParsed?.content.fields.project">
      <UiWalkthroughPopup
        v-if="user && user.email && isProteinProducerIndex(dataSourceParsed)"
        :steps="walkthroughSteps"
        chapter="1"
        tourname="PI - Company Ranking"
        @walk="onWalk"
      />

      <TextSectionTitle
        v-if="
          isWorkingConditions(dataSourceParsed)
            || isProteinDiversification(dataSourceParsed)
        "
      >
        Company Data
      </TextSectionTitle>
      <TextSectionTitle v-else>
        Company Ranking
      </TextSectionTitle>

      <MembersOnly
        :show-content="true"
        :show-push="true"
      >
        <TableCompaniesHeatmap
          v-if="
            filteredCompanies
              && dataSourceByYear
              && (isWorkingConditions(dataSourceParsed)
                || isProteinDiversification(dataSourceParsed)
                || isSeafoodTraceability(dataSourceParsed))
          "
          :data-source-parsed="dataSourceByYear"
          :chart-years="chartYears"
          :year-selected="yearSelected"
          :companies="
            filteredCompanies.filter(
              (company): company is TypePdCompany | TypeWcCompany =>
                isProteinDiversification(dataSourceParsed)
                || isWorkingConditions(dataSourceParsed)
                || isSeafoodTraceability(dataSourceParsed),
            )
          "
          @on-year="onYear"
        />

        <template
          v-else-if="
            isProteinProducerIndex(dataSourceParsed)
              || isSustainableProteins(dataSourceParsed)
              || isSeafoodTraceability(dataSourceParsed)
          "
        >
          <div>
            <ChartControls
              :filter-panel-blocks="filterPanelBlocks"
              :filter-titles="filterTitles"
              :x-values="xValues ?? []"
              :x-values-titles="xValuesTitles ?? []"
              :x-values2="
                isSustainableProteins(dataSourceParsed) ? [] : subKpisCodes
              "
              :x-values2-titles="subKpisTitles"
              :x-selected="xSelected"
              :x2-selected="x2Selected"
              x-title="Assessment Criteria"
              :is-company-ranking="true"
              :years="chartYears"
              :year="yearSelected"
              :control-pillars="false && isProteinProducerIndex(dataSourceParsed)"
              :show-pillars="showPillars"
              :pillars="[...pillars?.map(pillar => pillar.key)]"
              :pillar-selected="pillarSelected"
              :disable-years="showPillars"
              @on-x-select="onXSelect"
              @on-x2-select="onX2Select"
              @on-filter-change="onFilterChange"
              @on-year="onYear"
              @on-sort="onSort"
              @on-title-update="chartDataLayer.title = $event"
              @on-pillars="onPillars"
              @on-pillar="onPillar"
            />

            <div class="relative ml-2 flex pl-4 md:ml-1 lg:pl-0 2xl:ml-0">
              <div
                id="chartWalkthroughPoint"
                class="absolute left-6 top-4"
              />
              <div
                id="chartWalkthroughCompanyPoint"
                class="absolute left-[296px] top-8"
              />
              <ChartChart
                class="mb-5"
                :project="dataSourceParsed.content.fields.project"
                title-left="Score (%)"
                :min-score="0"
                :max-score="chartMaxScore"
                :is-bar-chart="true"
                :step-size="chartMaxScore % 100 === 0 ? 10 : 1"
                :items="chartCompanies"
                :bg-strips="chartBgStrips"
                :year-selected="chartYears[yearSelectedIndex]"
                :selected-outlook="selectedOutlook"
                :selected-kpi="mainX"
                :show-pillars="showPillars"
                :pillars="pillars"
                :force-hover-index="forceHoverIndex"
              />
            </div>

            <div
              class="tool-chart__keys mx-auto mb-2 flex items-center px-2 text-xs"
            >
              Companies
            </div>

            <div class="mb-5 flex items-center justify-between">
              <UiSwitch
                :items="['High to Low', 'Low to High']"
                :initial="'High to Low'"
                @select="onSort($event)"
              />

              <div
                v-if="showPillars"
                class="justify-center gap-4 rounded border border-ui-grey2 bg-level-standard p-2.5 text-xs font-medium uppercase tracking-widest max-sm:-mt-1 max-sm:mb-3 max-sm:py-1 sm:flex"
              >
                <div
                  v-for="pillar in pillars"
                  :key="pillar.key"
                  class="flex items-center font-normal max-sm:my-1 max-sm:text-[9px]"
                >
                  {{ pillar.key }}
                  <span
                    :style="{ 'background-color': pillar.colour }"
                    class="ml-2 inline-block size-[10px] rounded-lg sm:size-3"
                  />
                </div>
              </div>

              <div v-if="showPillars">
                <NuxtLink
                  to="/tools/protein-producer-index#methodology"
                  class="text-sm underline"
                >
                  More information in pillar scoring
                </NuxtLink>
              </div>
            </div>
          </div>

          <TableCompanies
            v-if="
              filteredCompanies
                && filteredCompanies[0]
                && ('Name Short' in filteredCompanies[0]
                  || 'Name' in filteredCompanies[0])
            "
            :data-source-parsed="dataSourceParsed"
            :items="
              filteredCompanies.filter(
                (company): company is TypePpiCompany | TypeSpCompany | TypeStCompany =>
                  isProteinProducerIndex(dataSourceParsed)
                  || isSustainableProteins(dataSourceParsed)
                  || isSeafoodTraceability(dataSourceParsed),
              )
            "
            :filter-panel-blocks="filterPanelBlocks"
            :filter-titles="filterTitles"
            :outlook-codes="outlookCodes"
            :selected-outlook="selectedOutlook"
            :selected-kpi="mainX"
            :kpis="xValues.filter((kpi) => kpi !== 'Risk Score')"
            @on-type-change="
              chartDataLayer.companiesView = $event;
              pushDataLayerEvent('evChartCompaniesView', chartDataLayer);
            "
          />
        </template>
      </MembersOnly>
    </UiContainer>
  </section>
</template>
