<script setup lang="ts">
defineProps<{
  text: string;
}>();

const showTooltip = ref(false);
</script>

<template>
  <b
    v-tooltip="{
      content: text,
      show: showTooltip,
    }"
    @mouseover="showTooltip = true"
  />
</template>

<style lang="scss">
.v-popper--theme-tooltip .v-popper__inner {
  background-color: white;
  color: #0d1033;
  border: 1px solid #e7e7eb;
  padding: 12px;
  font-size: 0.75rem;
  max-width: 300px;
}

.v-popper--theme-tooltip .v-popper__arrow-outer {
  border-color: #e7e7eb;
  position: relative;

  &::after {
    content: "◢";
    position: absolute;
    color: white;
    transform: rotate(45deg);
    font-size: 0.5rem;
    bottom: 2px;
    left: -4px;
  }
}

.v-popper--theme-tooltip[data-popper-placement^="bottom"]
  .v-popper__arrow-outer::after {
  transform: rotate(225deg);
  bottom: auto;
  top: 2px;
}
</style>
