<script setup lang="ts">
import type { SwiperContainer } from "swiper/element";
import {
  Autoplay as SwiperAutoplay,
  Thumbs as SwiperThumbs,
} from "swiper/modules";
import type { Swiper as TypeSwiper } from "swiper/types";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeCarouselImageText } from "~/types/contentful";
import type { TypeButton } from "~/types/TypeButton";

defineProps<{
  fields: TypeCarouselImageText<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"] & { button: TypeButton[] };
}>();

const swiperContainer = ref<SwiperContainer>();
const thumbsSwiperContainer = ref<SwiperContainer>();
const activeSlide = ref();
const progress = reactive<Record<number, number>>({});

watch(swiperContainer, () =>
  swiperContainer.value?.swiper.on(
    "autoplayTimeLeft",
    (swiper: TypeSwiper, _timeLeft: number, percentage: number) => {
      activeSlide.value = swiper.activeIndex;
      progress[swiper.activeIndex] = 100 - percentage * 100;
    },
  ),
);
</script>

<template>
  <UiContainer class="py-4 md:py-6">
    <div
      v-if="fields.displaySectionTitle"
      class="w-full"
    >
      <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
    </div>
    <div class="max-lg:hidden">
      <swiper-container
        v-if="thumbsSwiperContainer"
        ref="swiperContainer"
        :modules="[SwiperAutoplay, SwiperThumbs]"
        :slides-per-view="1"
        :space-between="30"
        :loop="true"
        :autoplay="{
          delay: 25000,
          disableOnInteraction: false,
        }"
        :auto-height="true"
        :thumbs="{ swiper: thumbsSwiperContainer.swiper }"
      >
        <swiper-slide
          v-for="slide in fields.activities"
          :key="slide?.fields.title"
        >
          <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div>
              <TextBadge>{{ slide?.fields.type }}</TextBadge>
              <h3 class="my-3">
                {{ slide?.fields.title }}
              </h3>
              <div class="wysiwyg mb-4">
                <RichTextRenderer :document="slide?.fields.text" />
              </div>
              <NuxtLink
                v-for="button in (slide?.fields.button as unknown as TypeButton[])"
                :key="button?.title"
                class="btn mb-3 mr-3"
                :class="'btn--' + button?.type"
                :to="button?.url"
              >
                {{ button?.title }}
              </NuxtLink>
            </div>
            <NuxtImg
              v-if="slide?.fields.image?.fields.file?.url"
              :src="slide.fields.image.fields.file.url"
              :alt="slide.fields.image.fields.title"
              :width="660"
            />
          </div>
        </swiper-slide>
      </swiper-container>

      <swiper-container
        ref="thumbsSwiperContainer"
        :modules="[SwiperAutoplay, SwiperThumbs]"
        :loop="true"
        :watch-slides-progress="true"
        :slides-per-view="fields.activities?.length"
        :space-between="30"
        class="mt-4"
      >
        <swiper-slide
          v-for="(slide, index) in fields.activities"
          :key="slide?.fields.title"
        >
          <div class="cursor-pointer">
            <div class="relative mb-2 w-full before:absolute before:left-0 before:top-1/2 before:h-px before:w-full before:bg-dark-blue/20 before:content-['']">
              <div
                class="h-[3px]"
                :class="{ 'bg-light-royal-blue': activeSlide === index }"
                :style="`width: ${progress[activeSlide]}%`"
              />
            </div>
            <div class="mb-3 text-xs font-semibold text-ui-grey3">
              {{ slide?.fields.type }}
            </div>
            <div :class="{ 'font-semibold': activeSlide === index }">
              {{ slide?.fields.title }}
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
    <div class="lg:hidden">
      <div
        v-for="slide in fields.activities"
        :key="slide?.fields.title"
        class="mb-4 border-b border-ui-grey3/50 pb-2"
      >
        <div class="grid grid-cols-1 gap-4">
          <div>
            <TextBadge>{{ slide?.fields.type }}</TextBadge>
            <h3 class="my-3">
              {{ slide?.fields.title }}
            </h3>
            <div class="wysiwyg mb-4">
              <RichTextRenderer :document="slide?.fields.text" />
            </div>
            <NuxtLink
              v-for="button in slide?.fields.button as unknown as TypeButton[]"
              :key="button?.title"
              class="btn mb-3 mr-3"
              :class="'btn--' + button?.type"
              :to="button?.url"
            >
              {{ button?.title }}
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </UiContainer>
</template>
