<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    options: string[];
    optionNames?: string[];
    selectedOption?: string;
    label: string;
    placeholder?: string;
    allowBlank?: boolean;
    disabled?: boolean;
    id?: string;
  }>(),
  {
    options: () => [],
    optionNames: () => [],
    selectedOption: "",
    label: "",
    placeholder: "",
    allowBlank: false,
  },
);

const emit = defineEmits(["click", "select"]);

const selectedOptionName = computed(() => (
  props.optionNames.length > 1
    ? props.optionNames[props.options.indexOf(props.selectedOption)]
    : props.selectedOption || props.placeholder || "Please select"
));

const open = ref(false);

const handleClick = () => {
  open.value = !open.value;
  emit("click", open.value);
};

const selectItem = (value: string, index: number) => {
  emit("select", value, index);
};
</script>

<template>
  <div
    :id="id"
    v-click-outside="() => (open = false)"
    class="inline-block h-6 cursor-pointer rounded-sm border border-ui-grey2"
    :class="{ 'pointer-events-none opacity-50': options.length === 1 && options[0] === '' || props.disabled === true }"
    @click="handleClick"
  >
    <div class="flex h-full items-center justify-between">
      <div class="px-2 py-1">
        <label
          v-if="label"
          class="block text-xs font-semibold"
          :for="props.label"
        >{{ props.label }}</label>
        <div>{{ selectedOptionName }}</div>
      </div>
      <NuxtIcon
        name="fairr:arrow"
        size="20"
        class="me-2"
      />
    </div>

    <div
      class="relative z-10 -mx-px border border-ui-grey2 bg-white py-1"
      :class="{ invisible: !open }"
    >
      <div
        v-if="allowBlank && selectedOption"
        class="p-2"
        style="opacity: 0.6"
        @click="() => selectItem('', -1)"
      >
        Select none
      </div>
      <div
        v-for="(item, index) of options"
        :key="index"
        :data-index="index"
        class="p-2 font-semibold"
        :class="{
          'font-extrabold': item === selectedOption,
          'hidden': optionNames && optionNames[index] === '',
        }"
        @click="() => selectItem(item, index)"
      >
        {{ optionNames && optionNames.length > 1 ? optionNames[index] : item }}
      </div>
    </div>
  </div>
</template>
