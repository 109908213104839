<script setup lang="ts">
import salesforceCountries from "~/lib/salesforce/countries";
import { theme } from "#tailwind-config";

const props = defineProps<{
  formEndpoint: string;
  languages?: string[];
  noThanks?: boolean;
}>();

const emit = defineEmits(["success"]);

const showThanks = ref(false);

const success = ref(false);

const open = ref(false);

const pending = ref(false);

const click = (event: Event) => {
  event.preventDefault();

  if (success.value) {
    // Don't open form a second time if already successfully submitted
    emit("success");

    if (!props.noThanks) {
      showThanks.value = true;
    }
  } else {
    open.value = true;
  }
};

const handle = async (event: Event) => {
  pending.value = true;

  const formData = new FormData(event.target as HTMLFormElement);

  try {
    const data = await $fetch("/api/pardot/submit", {
      method: "POST",
      body: Object.fromEntries(formData.entries()),
    });

    if (data === "ok") {
      if (props.noThanks) {
        open.value = false;
      } else {
        showThanks.value = true;
      }

      success.value = true;

      emit("success");
    } else {
      throw new Error(data);
    }
  } catch (error) {
    alert("Something went wrong, please try again later");

    pending.value = false;

    throw error;
  }

  turnstile.value?.reset();

  pending.value = false;
};

const organisationTypes = [
  "Charities & NGOs",
  "Banks & Development Finance",
  "Business & Corporations",
  "Investors & Asset Owners",
  "Media & Communications",
  "Policy & Government",
  "Service Provider",
  "Individual",
  "Other",
];

const turnstile = ref();

const turnstileToken = ref("");
</script>

<template>
  <slot :on="{ click }" />
  <LazyOtherModal
    v-model="open"
    class="flex items-center justify-center"
  >
    <div
      class="relative w-full max-w-[872px] rounded-sm bg-ui-grey4 text-center before:absolute before:left-0 before:top-0 before:h-2 before:w-full before:content-[''] before:[background-image:linear-gradient(to_bottom,var(--color-ui-grey4)_0_2px,var(--color-black)_2px_4px,var(--color-ui-grey4)_4px_6px,var(--color-black)_6px_8px)]"
      :style="{ '--color-ui-grey4': theme.colors['ui-grey4'], '--color-black': theme.colors['black'] }"
    >
      <div
        v-if="showThanks"
        class="p-5"
      >
        <h3 class="mb-3">
          Thank you
        </h3>
        <p>You should receive an email containing your download shortly.</p>
      </div>
      <template v-else>
        <div class="border-b-2 border-b-ui-grey2 p-5 pb-4">
          <h3 class="mb-3">
            Already a FAIRR Member?
          </h3>
          <p class="mb-4">
            Login to have access to all member-only resources.
          </p>
          <NuxtLink
            to="/login"
            class="btn"
          >
            Login
          </NuxtLink>
        </div>
        <div class="p-5">
          <h3 class="mb-3">
            Not a member of FAIRR?
          </h3>
          <p class="mb-4">
            Please provide the following details to receive this resource via
            email.
          </p>
          <form @submit.prevent="handle">
            <input
              type="hidden"
              name="form_endpoint"
              :value="formEndpoint"
            />
            <div class="mb-4 grid grid-cols-2 gap-4">
              <div class="col-span-2 md:col-span-1">
                <div class="form-floating">
                  <input
                    id="first-name-input"
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    aria-label="First name"
                    required
                  />
                  <label for="first-name-input">First name</label>
                </div>
              </div>
              <div class="col-span-2 md:col-span-1">
                <div class="form-floating">
                  <input
                    id="last-name-input"
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    aria-label="Last name"
                    required
                  />
                  <label for="last-name-input">Last name</label>
                </div>
              </div>
              <div class="col-span-2 md:col-span-1">
                <div class="form-floating">
                  <input
                    id="email-address-input"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    aria-label="Email address"
                    required
                  />
                  <label for="email-address-input">Email address</label>
                </div>
              </div>
              <div class="col-span-2 md:col-span-1">
                <div class="form-floating">
                  <input
                    id="organisation-input"
                    type="text"
                    name="organisation"
                    placeholder="Organisation"
                    aria-label="Organisation"
                    required
                  />
                  <label for="organisation-input">Organisation</label>
                </div>
              </div>
              <div class="col-span-2">
                <div class="form-floating">
                  <select
                    id="organisationtype-input"
                    name="organisationtype"
                    placeholder="Organisation Type*"
                    aria-label="Organisation Type*"
                    required
                  >
                    <option
                      value=""
                      selected
                    />
                    <option
                      v-for="organisationType in organisationTypes"
                      :key="organisationType"
                      :value="organisationType"
                    >
                      {{ organisationType }}
                    </option>
                  </select>
                  <label for="organisationtype-input">Organisation Type</label>
                </div>
              </div>
              <div class="col-span-2">
                <div class="form-floating">
                  <select
                    id="country-input"
                    name="country"
                    placeholder="Country*"
                    aria-label="Country*"
                    required
                  >
                    <option
                      value=""
                      selected
                    />
                    <option
                      v-for="country in salesforceCountries"
                      :key="country"
                      :value="country"
                    >
                      {{ country }}
                    </option>
                  </select>
                  <label for="country-input">Country</label>
                </div>
              </div>
              <div
                v-if="languages?.length"
                class="col-span-2"
              >
                <div class="form-floating">
                  <select
                    id="language-input"
                    name="language"
                    placeholder="Language*"
                    aria-label="Language*"
                    required
                  >
                    <option
                      v-for="language in languages"
                      :key="language"
                    >
                      {{ language }}
                    </option>
                  </select>
                  <label for="language-input">Language</label>
                </div>
              </div>
            </div>
            <NuxtTurnstile
              ref="turnstile"
              v-model="turnstileToken"
              :options="{
                appearance: 'interaction-only',
              }"
              class="mb-4"
            />
            <ClientOnly>
              <button
                class="btn btn--icon btn--email w-[120px] justify-center text-base"
                :disabled="!turnstileToken || pending"
              >
                Submit
              </button>
            </ClientOnly>
          </form>
        </div>
      </template>
    </div>
  </LazyOtherModal>
</template>

<style lang="scss" scoped>
.form-pardot::before {
  background: linear-gradient(
    to bottom,
    theme("colors.ui-grey4") 0 2px,
    rgb(0 0 0 / 60%) 2px 4px,
    theme("colors.ui-grey4") 4px 6px,
    rgb(0 0 0 / 60%) 6px 8px
  );
}
</style>
