<script setup lang="ts">
import type { EntryCollection } from "contentful";
import type { TypeReportSkeleton } from "~/types/contentful";

const props = defineProps<{
  articleId?: string;
  explicitIds?: string[];
  themeOrTopicIds?: string[];
}>();

const { data: reports } = await useLazyFetch<
  EntryCollection<TypeReportSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/reports", {
  query: {
    ids: props.explicitIds,
    ...(!props.explicitIds?.length
      ? { themeOrTopicIds: props.themeOrTopicIds }
      : {}),
    exceptIds: props.articleId,
  },
  transform: useCircularProtect,
});
</script>

<template>
  <div>
    <h4
      v-if="reports?.total"
      class="!mb-4"
    >
      Related Content
    </h4>
    <div
      v-for="report in reports?.items"
      :key="report.sys.id"
      class="mb-4"
    >
      <NuxtLink
        :to="getUrl(report)"
        class="!flex gap-3 after:hidden"
      >
        <NuxtImg
          v-if="report.fields.image"
          :src="report.fields.image.fields.file?.url"
          :alt="report.fields.image.fields.title"
          :width="120"
          class="max-h-[100px] min-w-[120px] object-cover object-top"
        />
        <h6 class="mb-0 !text-sm">
          {{ report.fields.title }}
        </h6>
      </NuxtLink>
    </div>
  </div>
</template>
