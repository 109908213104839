<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers.js";
import type { TypeBlockAccordion } from "~/types/contentful";

defineProps<{
  fields: TypeBlockAccordion<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const showCopy = ref(false);
</script>

<template>
  <UiContainer
    :class="{
      'py-4 md:py-6': !fields.removeWhitespace,
    }"
  >
    <div
      class="mb-3 cursor-pointer select-none overflow-hidden rounded bg-level-standard p-3"
      @click="() => showCopy = !showCopy"
    >
      <div>
        <h4 class="mb-0 flex justify-between gap-3">
          {{ fields.title }}
          <span
            v-if="fields.icon?.fields.file"
            class="grow"
          >
            <NuxtImg
              class="absolute -mt-2 h-[44px]"
              :src="fields.icon.fields.file.url"
              :alt="fields.icon.fields.title"
              :height="44"
              loading="lazy"
            />
          </span>
          <NuxtIcon
            name="fairr:arrow"
            size="24"
            class="shrink-0"
            :class="{ 'rotate-180': showCopy }"
          />
        </h4>

        <div
          v-if="showCopy"
          class="wysiwyg mt-3 border-t border-ui-grey2 pt-3 leading-6"
        >
          <RichTextRenderer
            :document="fields.copy"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </div>
  </UiContainer>
</template>
