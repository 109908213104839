<script setup lang="ts">
import type { Entry, EntryCollection } from "contentful";
import {
  isTypeArticle,
  isTypeCompany,
  isTypeDownload,
  isTypeProject,
  isTypeReport,
  isTypeTheme,
  isTypeTopic,
  type TypeArticleSkeleton,
  type TypeCompanySkeleton,
  type TypeDownloadSkeleton,
  type TypeProjectSkeleton,
  type TypeReportSkeleton,
  type TypeThemeSkeleton,
} from "~/types/contentful";
import type { TypeSaveableContent } from "~/types/TypeSaveableContent";

const props = defineProps<{
  savedItems: TypeSaveableContent[];
  userMenuOpen: boolean;
}>();

const emit = defineEmits(["logout", "onUserClick"]);

const logout = (event: Event) => {
  emit("logout", event);
};
const onUserClick = (event: Event) => {
  emit("onUserClick", event);
};

const bookmarkSavedItems = computed(() =>
  props.savedItems.filter(
    (savedItem): savedItem is Entry<TypeArticleSkeleton | TypeDownloadSkeleton | TypeReportSkeleton, "WITHOUT_UNRESOLVABLE_LINKS"> => isTypeArticle(savedItem) || isTypeDownload(savedItem) || isTypeReport(savedItem),
  ),
);

const { data: suggestedBookmarks } = await useLazyFetch<
  EntryCollection<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/articles", {
  query: {
    perPage: 3,
  },
  transform: useCircularProtect,
});

const companySavedItems = computed(() =>
  props.savedItems.filter((savedItem): savedItem is Entry<TypeCompanySkeleton, "WITHOUT_UNRESOLVABLE_LINKS"> => isTypeCompany(savedItem)),
);

const { data: suggestedCompanies } = await useLazyFetch<
  EntryCollection<TypeCompanySkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/companies", {
  query: {
    perPage: 3,
  },
  transform: useCircularProtect,
});

const engagementSavedItems = computed(() =>
  props.savedItems.filter(
    (savedItem): savedItem is Entry<TypeProjectSkeleton, "WITHOUT_UNRESOLVABLE_LINKS"> => isTypeProject(savedItem) && savedItem.fields.type === "Engagement",
  ),
);

const toolSavedItems = computed(() =>
  props.savedItems.filter(
    (savedItem): savedItem is Entry<TypeProjectSkeleton, "WITHOUT_UNRESOLVABLE_LINKS"> => isTypeProject(savedItem) && savedItem.fields.type === "Tool",
  ),
);

const { data: allProjects }
  = await useLazyFetch<Entry<TypeProjectSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]>(
    "/api/projects",
  );

const suggestedEngagements = computed(() => [
  ...(allProjects.value
    ? allProjects.value.filter((project) => project.fields.type === "Engagement")
    : []),
]);

const suggestedTools = computed(() => [
  ...(allProjects.value
    ? allProjects.value.filter((project) => project.fields.type === "Tool")
    : []),
]);

const themeOrTopicIds = computed(() =>
  props.savedItems
    .filter((savedItem) => isTypeTopic(savedItem) || isTypeTheme(savedItem))
    .map((savedItem) => {
      return savedItem.sys.id;
    }),
);

const { data: articlesByTopicSavedItems } = await useLazyFetch<
  EntryCollection<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/articles", {
  query: {
    themeOrTopicIds,
  },
  transform: useCircularProtect,
});

const articlesByTopicSortedSavedItems = computed(() => [
  ...(articlesByTopicSavedItems.value?.items ?? []).sort((a, b) =>
    b.fields.publishDate.localeCompare(a.fields.publishDate),
  ),
]);

const { data: allThemes }
  = await useLazyFetch<Entry<TypeThemeSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]>(
    "/api/themes",
  );
</script>

<template>
  <div
    class="drawer fixed top-0 z-[51] size-full bg-ui-grey4 text-dark-blue-grey-two transition-all md:w-[450px]"
    :class="{
      'right-0': userMenuOpen,
      '-right-full md:right-[-450px]': !userMenuOpen,
    }"
  >
    <div
      class="overlay"
      @click="onUserClick"
    />
    <div class="relative h-full bg-ui-grey4 py-4">
      <div class="flex w-full justify-between px-4 pb-3 shadow-sm transition-all">
        <a
          class="flex items-center"
          href=""
          @click="logout"
        >
          <div class="mr-2">
            <NuxtIcon
              name="fairr:logout"
              size="22"
            />
          </div>
          Logout
        </a>
        <NuxtIcon
          name="fairr:cross"
          size="26"
          class="cursor-pointer"
          @click="onUserClick"
        />
      </div>

      <div class="h-full overflow-y-scroll px-4">
        <div class="mt-4 flex flex-col gap-4">
          <!-- BOOKMARKS -->
          <DashboardDrawerList
            v-if="bookmarkSavedItems.length"
            :saved-items="bookmarkSavedItems"
            title="Bookmarks"
            type="bookmarks"
            options="saved"
          />
          <DashboardDrawerList
            v-if="!bookmarkSavedItems.length"
            :saved-items="suggestedBookmarks?.items ?? []"
            title="Recommendations for you"
            type="bookmarks"
            options="suggested"
          />

          <!-- COMPANIES -->
          <DashboardDrawerList
            v-if="companySavedItems.length"
            :saved-items="companySavedItems"
            title="Companies you follow"
            type="companies"
            options="saved"
          />
          <DashboardDrawerList
            v-if="!companySavedItems.length"
            :saved-items="suggestedCompanies?.items ?? []"
            title="Suggested companies to follow"
            type="companies"
            options="suggested"
          />

          <!-- ENGAGEMENTS -->
          <DashboardDrawerList
            v-if="engagementSavedItems.length"
            :saved-items="engagementSavedItems"
            title="Engagements you follow"
            type="engagements"
            options="saved"
          />
          <DashboardDrawerList
            v-if="!engagementSavedItems.length"
            :saved-items="suggestedEngagements?.slice(0, 3) ?? []"
            title="Suggested engagements to follow'"
            type="engagements"
            options="suggested"
          />

          <!-- TOOLS -->
          <DashboardDrawerList
            v-if="toolSavedItems.length"
            :saved-items="toolSavedItems"
            title="Tools you follow"
            type="tools"
            options="saved"
          />
          <DashboardDrawerList
            v-if="!toolSavedItems.length"
            :saved-items="suggestedTools.slice(0, 2) ?? []"
            title="Suggested tools to follow"
            type="tools"
            options="suggested"
          />

          <!-- LATEST FROM YOUR TOPICS -->
          <DashboardDrawerList
            v-if="themeOrTopicIds.length"
            :saved-items="articlesByTopicSortedSavedItems"
            title="Latest from your topics"
            type="articles"
          />

          <!-- THEMES -->
          <DashboardDrawerList
            v-if="allThemes?.length"
            :saved-items="allThemes"
            title="What to follow"
            type="themes"
          />
        </div>
      </div>
    </div>
  </div>
</template>
