<script setup lang="ts">
import type { Entry } from "contentful";
import {
  type TypeThemeSkeleton,
  type TypeTopicSkeleton,
  isTypeTheme,
  isTypeTopic,
} from "~/types/contentful";

const props = defineProps<{
  themeOrTopics: Entry<
    TypeThemeSkeleton | TypeTopicSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >[];
  title?: string;
  bgColour?: string;
}>();
</script>

<template>
  <div
    v-if="props.title"
    class="clear-both mb-[6px] mt-4 text-sm uppercase tracking-widest"
  >
    {{ props.title }}
  </div>

  <template
    v-for="(themeOrTopic, index) in themeOrTopics"
    :key="index"
  >
    <NuxtLink
      :to="
        isTypeTheme(themeOrTopic) && themeOrTopic.fields.slug
          ? `/themes/${themeOrTopic.fields.slug}`
          : isTypeTopic(themeOrTopic) && themeOrTopic.fields.theme?.fields.slug
            ? `/themes/${themeOrTopic.fields.theme.fields.slug}`
            : '/themes'
      "
    >
      <TextBadge
        class="mb-[6px]"
        :class="index < themeOrTopics.length - 1 && 'mr-2 whitespace-nowrap'"
        :colour="
          isTypeTheme(themeOrTopic)
            ? themeOrTopic.fields.colour
            : isTypeTopic(themeOrTopic)
              ? themeOrTopic.fields.theme?.fields.colour
              : undefined
        "
        :bg-colour="bgColour"
      >
        {{ themeOrTopic.fields.title }}
      </TextBadge>
    </NuxtLink>
  </template>
</template>
