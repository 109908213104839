<script setup lang="ts">
import type { TypeSpCompany } from "~/types/csv/TypeSp";
import type { TypePpiCompany } from "~/types/csv/TypePpi";
import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed";
import { Colours } from "~/lib/company-profile/colours";
import {
  isCompanyKey,
  isProteinProducerIndex,
} from "~/lib/company-profile/type-guards";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import type { TypeStCompany } from "~/types/csv/TypeSt";

const user = useUser();

const props = defineProps<{
  dataSourceParsed: TypeDataSourceParsed<TypeSpCompany | TypeStCompany | TypePpiCompany>;
  items: (TypeSpCompany | TypeStCompany | TypePpiCompany)[];
  selectedOutlook?: string;
  selectedKpi: string;
  kpis: string[];
  showSupplierSize?: boolean;
  showSupplierDate?: boolean;
  showHeatmap?: boolean;
  hideSelector?: boolean;
  hideSearch?: boolean;
}>();

const emit = defineEmits(["onTypeChange"]);

const showHeatmap = ref<boolean>(props.showHeatmap);

const colours = new Colours(
  props.dataSourceParsed.content.fields.project?.fields.slug,
);

const kpiColor = (
  kpi: string,
  company: NonNullable<typeof props.dataSourceParsed.company>,
  max = 100,
) => {
  if (isCompanyKey(kpi, company)) {
    if (company[kpi] === "") return "#bababa";
    if (String(kpi).includes("ALP") && company[kpi] === "0") return "#bababa";
    return colours.riskColour(
      String(kpi).includes("ALP") ? 100 : Number(company[kpi]),
      max,
    );
  }
  return "";
};

const keys = [
  "Company",
  "Ticker",
  "Name",
  "Country",
  "Region",
  "Company Type",
  "Main Protein Category",
];

const input = ref("");

const itemsSearched = computed(() =>
  props.items.filter((item) => {
    for (const field of keys) {
      if ("Name Short" in item) {
        if (
          String(item[field as keyof TypePpiCompany])
            .toLowerCase()
            .includes(input.value.toLowerCase())
        )
          return true;
      } else if ("Company Name" in item) {
        if (
          String(item[field as keyof TypeStCompany])
            .toLowerCase()
            .includes(input.value.toLowerCase())
        )
          return true;
      } else if (
        String(item[field as keyof TypeSpCompany])
          .toLowerCase()
          .includes(input.value.toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  }),
);

const proteins = ["Beef", "Poultry and eggs", "Pork", "Dairy", "Aquaculture"];
const companyProteins = (company: TypePpiCompany) => {
  return proteins
    .filter((p) => company[p as keyof TypePpiCompany] === "Yes")
    .join(", ");
};
</script>

<!-- eslint-disable-next-line vue/max-lines-per-block -->
<template>
  <div
    class="table--companies pt-2"
    :class="{ selectedOutlook }"
  >
    <div
      v-if="isProteinProducerIndex(dataSourceParsed)"
      class="flex justify-between"
    >
      <div
        v-if="!props.hideSearch"
        class="search mb-3 mr-2 flex h-5 w-full items-center rounded-sm border border-ui-grey2 md:mb-0 md:max-w-[700px]"
      >
        <NuxtIcon
          class="mx-2"
          name="fairr:search"
          size="24"
        />
        <input
          v-model="input"
          type="text"
          placeholder="Search for..."
        />
      </div>
      <UiRadio
        v-if="!props.hideSelector"
        class="heatmap-switch rounded border border-ui-grey2 px-[4px] max-lg:hidden"
        :items="['Table', 'Heatmap']"
        @select="
          (checked: boolean, title: string) => {
            showHeatmap = title === 'Heatmap';
            emit('onTypeChange', title);
          }
        "
      />
    </div>
    <table
      class="mt-3 w-full"
      aria-label="Companies"
    >
      <thead>
        <tr>
          <td
            data-field="Name"
          >
            Company
          </td>
          <td
            v-show="!showHeatmap"
            class="max-lg:hidden"
            data-field="Market Cap"
          >
            Market Cap
          </td>
          <td
            v-show="!showHeatmap"
            class="max-lg:hidden"
            data-field="Country"
          >
            Country
          </td>
          <td
            v-show="!showHeatmap"
            class="max-lg:hidden"
            data-field="Region"
          >
            Region
          </td>
          <td
            v-if="!isProteinProducerIndex(dataSourceParsed)"
            v-show="!showHeatmap"
            class="hidden lg:table-cell"
            data-field="Company type"
          >
            Company Type
          </td>

          <td
            v-if="isProteinProducerIndex(dataSourceParsed)"
            v-show="!showHeatmap"
            class="max-lg:hidden"
            data-field="Protein"
          >
            Main Protein
          </td>

          <td
            v-if="isProteinProducerIndex(dataSourceParsed)"
            v-show="!showHeatmap"
            class="max-lg:hidden"
            data-field="Assessed Proteins"
          >
            Assessed Proteins
          </td>

          <td
            v-if="props.showSupplierDate"
            v-show="!showHeatmap"
            id="thYear"
            class="max-lg:hidden"
            data-field="Start Year"
          >
            Start Year
          </td>

          <td
            v-if="props.showSupplierSize"
            v-show="!showHeatmap"
            id="thRevenue"
            class="relative max-lg:hidden"
            data-field="Supplier Value"
          >
            <AppTooltip
              text="Percentage of annual revenue derived by the protein supplier from the relationship."
              class="absolute left-0 top-0 block size-full"
            />
            Supplier Revenue Share
          </td>

          <td
            v-if="isProteinProducerIndex(dataSourceParsed)"
            v-show="!showHeatmap"
            class="max-lg:hidden"
            data-field="Company Feedback"
          >
            Company Feedback
          </td>

          <template v-if="isProteinProducerIndex(dataSourceParsed)">
            <td
              v-for="kpi in kpis"
              v-show="showHeatmap"
              :key="kpi"
              class="grow max-md:!hidden"
              data-field="Heatmap"
            >
              {{ kpi.replace(/F$/, "") }}
            </td>
          </template>

          <td
            data-field="Score"
          >
            {{
              showHeatmap || selectedKpi === "Risk Score"
                ? "Risk Score"
                : selectedKpi + " Score" || "Score"
            }}
          </td>
          <td
            v-if="!isProteinProducerIndex(dataSourceParsed)"
            v-show="selectedOutlook"
            class=" max-md:!hidden"
            data-field="Outlook"
          >
            Outlook
          </td>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(company, index) in itemsSearched"
          :key="index"
          class="relative py-3 md:py-0"
        >
          <td
            class="max-lg:!pb-[46px] print:!pb-[24px]"
            data-field="Name"
          >
            <div class="flex items-center">
              <div class="w-[30px] pr-3">
                {{ "Ranking" in company ? company.Ranking : index + 1 }}
              </div>
              <div
                :id="index === 0 && showHeatmap ? 'walkthroughCompany' : ''"
                class="flex flex-col items-start"
              >
                <div class="text-sm">
                  {{ company.Ticker }}
                </div>
                <NuxtLink
                  :to="`/resources/companies-assessed/${company.Ticker}/${dataSourceParsed.content.fields.project?.fields.slug}`"
                  class="wysiwyg border-b border-ui-grey2 font-bold lg:text-sm"
                  @click="
                    pushDataLayerEvent('evChartCompanyClick', {
                      name: 'Company Name' in company ? company['Company Name'] : company.Name,
                    })
                  "
                >
                  {{ 'Company Name' in company ? company['Company Name'] : company.Name }}
                </NuxtLink>
              </div>
            </div>
          </td>

          <td
            v-if="'Market Cap' in company"
            v-show="!showHeatmap"
            class="bottom-0 left-[33px] max-lg:absolute max-lg:block max-lg:!w-1/3 max-lg:!border-0"
            data-field="Market Cap"
          >
            <div
              v-if="company['Market Cap']"
              class="me-2 text-sm"
            >
              ${{ Number(company["Market Cap"]).toFixed(2) }}bn
            </div>
          </td>

          <td
            v-if="'Country' in company"
            v-show="!showHeatmap"
            class="text-sm max-lg:!hidden"
            data-field="Country"
          >
            {{ company.Country }}
          </td>
          <td
            v-if="'Region' in company"
            v-show="!showHeatmap"
            class="bottom-0 left-[110px] text-sm max-lg:absolute max-lg:block max-lg:!w-1/3 max-lg:!border-0"
            data-field="Region"
          >
            {{ company.Region }}
          </td>

          <td
            v-if="'Company type' in company"
            v-show="!showHeatmap"
            class="max-lg:hidden"
            data-field="Company type"
          >
            <TextBadge class="badge--industry">
              {{ company["Company type"] }}
            </TextBadge>
          </td>

          <td
            v-if="'Main Protein Category' in company"
            v-show="!showHeatmap"
            class="text-sm max-lg:hidden"
            data-field="Protein"
          >
            {{ company["Main Protein Category"] }}
          </td>

          <td
            v-if="'Beef' in company"
            v-show="!showHeatmap"
            class="text-sm max-lg:hidden"
            data-field="Assessed Proteins"
          >
            {{ companyProteins(company) }}
          </td>

          <td
            v-if="props.showSupplierDate && 'Start Year' in company"
            v-show="!showHeatmap"
            class="text-sm max-lg:hidden"
            data-field="Start Date"
          >
            {{ company['Start Year'] !== "Not disclosed" ? company['Start Year'] : "ND" }}
          </td>

          <td
            v-if="props.showSupplierSize && 'Supplier Revenue Share' in company"
            v-show="!showHeatmap"
            class="text-sm max-lg:hidden"
            data-field="Supplier Size"
          >
            {{ company['Supplier Revenue Share'] !== "Not disclosed" ? company['Supplier Revenue Share'] : "ND" }}
          </td>

          <td
            v-if="'Company Feedback' in company"
            v-show="!showHeatmap"
            class="text-sm max-lg:hidden"
            data-field="Company Feedback"
          >
            <span
              v-if="company['Company Feedback'] === 'Yes'"
              class="mr-1 inline-block size-[22px] rounded-full bg-green text-center text-white"
            >
              <NuxtIcon
                name="fairr:tick"
                size="16"
              />
            </span>
            <span
              v-else
              class="mr-1 inline-block size-[22px] rounded-full bg-red text-center text-white"
            >
              <NuxtIcon
                name="fairr:cross"
                size="16"
              />
            </span>
            {{ company["Company Feedback"] }}
          </td>

          <template v-if="isProteinProducerIndex(dataSourceParsed)">
            <td
              v-for="kpi in kpis"
              v-show="showHeatmap"
              :key="kpi"
              class="max-md:!hidden"
              data-field="Heatmap"
            >
              <div
                v-if="isCompanyKey(kpi, company)"
                class="flex items-center justify-center rounded py-2 text-center font-semibold uppercase text-white max-lg:h-full"
                :style="{ backgroundColor: kpiColor(kpi, company) }"
              >
                {{
                  company[kpi] === "" ? "N/A" : Math.round(Number(company[kpi]))
                }}
              </div>
            </td>
          </template>

          <td
            v-if="isCompanyKey(selectedKpi, company)"
            class="justify-center text-sm max-lg:flex max-lg:!py-[23px]"
            data-field="Score"
          >
            <div class="text-center">
              <div v-if="user && !showHeatmap">
                <span class="mb-2 text-xl font-bold">{{
                  Math.round(Number(company[selectedKpi]))
                }}</span>
                <span class="relative top-[-3px] ml-[2px] tracking-widest">/{{ dataSourceParsed.scoring?.maxResult }}</span>
              </div>
              <div
                class="m-auto flex h-[36px] min-w-[100px] max-w-[140px] items-center justify-center rounded px-3 py-2 text-center text-sm font-semibold uppercase text-white"
                :style="{
                  backgroundColor: kpiColor(
                    showHeatmap && 'Risk Score' in company
                      ? 'Risk Score'
                      : selectedKpi,
                    company,
                  ),
                }"
              >
                {{
                  showHeatmap && "Risk Score" in company
                    ? Math.round(Number(company["Risk Score"]))
                    : company[selectedKpi] === ""
                      ? "N/A"
                      : String(selectedKpi).includes("ALP")
                        ? company[selectedKpi] === "0"
                          ? "No Info"
                          : "Best Practice"
                        : colours.level(
                          Number(company[selectedKpi]),
                          dataSourceParsed.scoring?.maxResult ?? 0,
                        )
                }}
              </div>
            </div>
          </td>

          <td
            v-if="
              'Overall Outlook' in company
                && isCompanyKey(selectedOutlook, company)
            "
            v-show="selectedOutlook"
            class="table-cell h-full text-sm max-md:!hidden"
            data-field="Outlook"
          >
            <NuxtIcon
              v-if="selectedOutlook && company[selectedOutlook]"
              :name="`fairr:${(company[selectedOutlook] as keyof typeof company).toLowerCase()}`"
              size="20"
              class="mr-2"
            />
            <span
              v-if="selectedOutlook"
              class="font-medium uppercase tracking-widest"
            >{{ company[selectedOutlook] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
table {
  width: calc(100% + 16px);
  margin-left: -8px;
}

thead td {
  @apply rounded-sm border-x-8 border-white bg-level-standard px-2 py-[12px] font-body text-sm font-medium uppercase leading-[1.17] tracking-[1.5px];
}

tbody td {
  @apply border-x-8 border-white px-2 py-[12px];

  border-bottom: 1px solid #f3f5f7;

  &[data-field="Heatmap"],
  &[data-field="Score"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.heatmap-switch {
  &:deep(.checkbox) {
    border-radius: 2px;
    font-weight: bold;
    min-width: 100px;
    justify-content: center;
    margin: 4px 0;
    margin-right: 0 !important;

    label {
      padding: 8px 12px;
    }

    input {
      display: none;
    }

    &[aria-checked="true"] {
      background-color: #4353ff;
      color: white;
      pointer-events: none;
    }
  }
}
</style>
