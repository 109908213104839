<script setup lang="ts">
import type { EntryCollection } from "contentful";
import type { TypeArticleSkeleton } from "~/types/contentful";

const props = defineProps<{
  title?: string;
  articleId?: string;
  authorId?: string;
  themeOrTopicIds?: string[];
  limit?: number;
}>();
const { data: articlesInTopic } = await useLazyFetch<
  EntryCollection<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/articles", {
  query: {
    ...(props.authorId ? { authorIds: props.authorId } : {}),
    ...(props.themeOrTopicIds
      ? { themeOrTopicIds: props.themeOrTopicIds }
      : {}),
    exceptIds: props.articleId,
    ...(props.limit ? { perPage: props.limit } : {}),
  },
  transform: useCircularProtect,
});
</script>

<template>
  <div>
    <h4
      v-if="articlesInTopic?.total"
      class="!mb-2 md:!mb-4"
    >
      {{ title ?? "Related Content" }}
    </h4>

    <div
      v-for="article in articlesInTopic?.items"
      :key="article.sys.id"
      class="mb-4"
    >
      <NuxtLink
        :to="getUrl(article)"
        class="!flex gap-3 after:hidden"
      >
        <NuxtImg
          v-if="article.fields.image"
          :src="article.fields.image.fields.file?.url"
          :alt="article.fields.image.fields.title"
          :width="120"
          :height="100"
          fit="contain"
        />
        <h6 class="mb-0 w-8/12 !text-sm">
          {{ article.fields.title }}
        </h6>
      </NuxtLink>
    </div>
  </div>
</template>
