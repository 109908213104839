<script setup lang="ts">
defineProps<{
  showMore: boolean;
}>();

const emit = defineEmits(["handleShowMore"]);

const handleShowMore = () => {
  emit("handleShowMore");
};
</script>

<template>
  <div
    class="cursor-pointer text-[12px] font-semibold underline"
    @click="handleShowMore"
  >
    See
    <span v-if="!showMore">more</span>
    <span v-else>less</span>
    <NuxtIcon
      name="fairr:show-more"
      size="20"
      class="ml-1 transition-transform duration-200"
      :class="{
        'rotate-180': showMore,
      }"
    />
  </div>
</template>
