<script setup lang="ts">
import type { Entry, EntryCollection, EntrySys } from "contentful";
import type {
  TypeArticleSkeleton,
  TypeCardBlockEvent,
  TypeEventSkeleton,
} from "~/types/contentful";

const props = defineProps<{
  fields: TypeCardBlockEvent<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  sys: EntrySys;
}>();

const route = useRoute();

const currentPage = ref(Number(route.query[`page_${props.sys.id}`]) || 1);
const perPage = 18;
const eventType = props.fields.eventType?.toLowerCase();
const container = ref<ComponentPublicInstance>();

const articlesIndexed = reactive<Record<string, Entry<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB"> | undefined>>({});

const { data: filterEvents, status: statusEvents } = await useLazyFetch<
  EntryCollection<TypeEventSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/events", {
  query: {
    eventType,
    page: computed(() => Number(route.query[`page_${props.sys.id}`]) || 1),
    perPage,
    includeArticles: true,
  },
  server: false,
  transform: useCircularProtect,
});

const events = computed(() => {
  return props.fields.event?.length
    ? props.fields.event.filter(
        (event): event is NonNullable<typeof event> => !!event,
      )
    : filterEvents.value?.items;
});

watch(events, async () => {
  const articles = await $fetch<
    EntryCollection<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
  >("/api/articles", {
    query: {
      eventIds: events.value?.map(
        (event) => event.sys.id,
      ),
      perPage,
    },
    parseResponse: (responseText) => useCircularProtect(JSON.parse(responseText)),
  });

  events.value?.forEach((event) => {
    articlesIndexed[event.sys.id] = articles.items.find(
      (article) => article.fields.event?.sys.id === event.sys.id,
    );
  });
}, {
  immediate: !!props.fields.event,
});

const paginationTotal = computed(() => {
  return props.fields.event
    ? props.fields.event.length
    : filterEvents.value?.total;
});

// Watch for changes in current page and scroll into view
watch(currentPage, async (page) => {
  container.value?.$el.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });

  await navigateTo({ query: { [`page_${props.sys.id}`]: page, hash: route.hash } });
});
</script>

<template>
  <UiContainer
    v-if="events?.length"
    ref="container"
    class="py-4 md:py-6"
  >
    <div class="flex flex-wrap gap-4">
      <div
        v-if="fields.displaySectionTitle"
        class="w-full"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
      <div
        class="flex flex-col gap-5"
        :class="{ 'opacity-10': statusEvents === 'pending' }"
      >
        <LazyCardBlockEventItem
          v-for="event in events"
          :key="event.sys.id"
          :event="event"
          :article="articlesIndexed[event.sys.id]"
          :event-type="eventType"
        />
      </div>
      <ClientOnly>
        <vue-awesome-paginate
          v-if="paginationTotal && paginationTotal > perPage"
          v-model="currentPage"
          :total-items="paginationTotal"
          :items-per-page="perPage"
          :max-pages-shown="4"
          class="pagination-container"
          type="link"
          :link-url="`?page_${sys.id}=[page]${route.hash}`"
        >
          <template #prev-button>
            prev
          </template>
          <template #next-button>
            next
          </template>
        </vue-awesome-paginate>
      </ClientOnly>
    </div>
  </UiContainer>
</template>
