<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { EntrySys } from "contentful";
import type { TypeBlockLegal } from "~/types/contentful";

const props = defineProps<{
  sys: EntrySys;
}>();

const { data: block } = await useLazyFetch<
  TypeBlockLegal<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/legal", {
  query: { id: props.sys.id },
  transform: useCircularProtect,
});
</script>

<template>
  <UiContainer class="border-t border-t-level-standard py-[40px]">
    <div
      class="flex gap-[40px] max-md:flex-col max-md:items-center max-md:gap-4"
    >
      <NuxtLink
        to="/"
        aria-label="Home"
      >
        <NuxtIcon
          name="fairr:logo"
          size="40"
        />
      </NuxtLink>
      <div class="flex flex-col gap-3 max-md:items-center">
        <div class="flex flex-wrap max-md:text-xs sm:gap-3">
          <NuxtLink
            v-for="(link, index) in block?.fields.links || []"
            :key="index"
            :to="link?.fields.link ? getUrl(link?.fields.link) : ''"
            class="max-md:text-center max-sm:min-w-[50%] max-sm:py-2"
          >
            {{ link?.fields.label }}
          </NuxtLink>
        </div>

        <div
          class="!text-xs !text-dark-blue-grey-two/50 max-md:text-center lg:w-2/3"
        >
          <RichTextRenderer :document="block?.fields.copy" />
        </div>
      </div>
      <div class="flex gap-[20px]">
        <NuxtLink
          :to="block?.fields.linkedIn"
          target="_blank"
        >
          <NuxtImg
            :src="block?.fields.linkedInLogo?.fields.file?.url"
            :alt="block?.fields.linkedInLogo?.fields.title"
            :width="40"
            :height="40"
            loading="lazy"
          />
        </NuxtLink>
        <NuxtLink
          :to="block?.fields.twitter"
          target="_blank"
        >
          <NuxtImg
            :src="block?.fields.twitterLogo?.fields.file?.url"
            :alt="block?.fields.twitterLogo?.fields.title"
            :width="40"
            :height="40"
            loading="lazy"
          />
        </NuxtLink>
        <NuxtLink
          :to="block?.fields.vimeo"
          target="_blank"
        >
          <NuxtImg
            :src="block?.fields.vimeoLogo?.fields.file?.url"
            :alt="block?.fields.vimeoLogo?.fields.title"
            :width="40"
            :height="40"
            loading="lazy"
          />
        </NuxtLink>
      </div>
    </div>
  </UiContainer>
</template>
