<script setup lang="ts">
import type { Entry } from "contentful";
import type { TypeThemeSkeleton, TypeTopicSkeleton } from "~/types/contentful";

const props = defineProps<{
  theme: Entry<TypeThemeSkeleton, "WITHOUT_UNRESOLVABLE_LINKS">;
}>();

const { data: topics } = await useLazyFetch<
  Entry<TypeTopicSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]
>("/api/topics", {
  query: {
    themeId: props.theme.sys.id,
  },
});
</script>

<template>
  <div class="mb-2 bg-white p-3">
    <div class="flex justify-between">
      <h5 class="mb-[12px] text-[16px] font-bold">
        {{ theme.fields.title }}
      </h5>
      <UiSaveButtonIcon
        :entry="theme"
        type="follow"
        colour="navy"
        :portal-action="true"
      />
    </div>
    <div class="flex flex-row flex-wrap gap-[6px]">
      <div
        v-for="topic in topics"
        :key="topic?.fields.title"
      >
        <UiSaveButtonBadge
          :topic="topic"
          colour="navy"
          variation="drawer"
          :portal-action="true"
        />
      </div>
    </div>
  </div>
</template>
