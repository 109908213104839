<script setup lang="ts">
import type { TypeTableSupplyChain } from "~/types/contentful";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed";
import type { TypeScaCompany } from "~/types/csv/TypeSca";
import { tool as walkthroughSteps } from "~/lib/walkthrough/supplier-chain-analysis.js";

const url = useRequestURL();
const baseURL = computed(() => {
  return url.protocol + "//" + url.host;
});

const props = defineProps<{
  fields: TypeTableSupplyChain<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const { data: dataSource } = await useLazyFetch<TypeDataSourceParsed<TypeScaCompany>>(
  "/api/data-source",
  {
    query: {
      id: props.fields.dataSource?.sys.id,
    },
  },
);

const user = useUser();

const mergedSource = computed(() => {
  const grouped = Object.groupBy(dataSource.value?.data ?? [], (row) => row.Ticker);

  return Object.keys(grouped).map((ticker) => ({
    ...grouped[ticker]?.find(Boolean),
    "Supplier": grouped[ticker]?.map((row) => row.Supplier),
    "Supplier Ticker": grouped[ticker]?.map((row) => row["Supplier Ticker"]),
  }));
});

const dataFields = computed(() =>
  Object.keys(dataSource.value?.data?.find(Boolean) ?? []) as (keyof TypeScaCompany)[],
);

const hiddenFields = [
  "Ticker",
  "Supplier Ticker",
  "Supplier Revenue Share",
  "Start Year",
];

const sortByField = ref("");
const sortAscending = ref(true);

const input = ref("");

const itemsSortedSearched = computed(() =>
  mergedSource.value.filter((item) => {
    for (const field of dataFields.value) {
      if (String(item[field]).toLowerCase().includes(input.value.toLowerCase())) {
        return true;
      }
    }

    return false;
  }),
);
</script>

<template>
  <section class="py-4 md:py-6">
    <UiContainer>
      <UiWalkthroughPopup
        v-if="user && user.email"
        :steps="walkthroughSteps"
        chapter="2"
        tourname="PI - Supply Chain Analysis"
      />

      <TextSectionTitle>
        {{ fields.name }}
      </TextSectionTitle>

      <div class="mt-4 flex items-center justify-between">
        <div class="relative flex items-center md:w-[70%]">
          <div
            id="supplySearch"
            class="absolute -top-4 left-9"
          />
          <div
            class="mb-3 mr-2 flex h-5 w-full items-center rounded-sm border border-ui-grey2 md:mb-0 md:max-w-[700px]"
          >
            <NuxtIcon
              class="mx-2"
              name="fairr:search"
              size="24"
            />
            <input
              v-model="input"
              class="w-full border-none focus:outline-none"
              type="text"
              placeholder="Search for..."
            />
          </div>
        </div>
      </div>

      <table
        v-if="mergedSource"
        class="mt-3 w-full align-top"
      >
        <thead>
          <tr>
            <td
              v-for="field in dataFields.filter((f) => !hiddenFields.includes(f))"
              :id="field === 'Supplier' ? 'tableHeadSupplier' : ''"
              :key="field"
              class="relative cursor-pointer select-none rounded-sm border-x-8 border-white bg-level-standard px-2 py-[12px] font-body text-[.75rem] font-medium uppercase leading-[1.17] tracking-[1.5px] first-of-type:border-l-0 last-of-type:border-r-0"
              :class="{ '!bg-pale-blue2': sortByField === field }"
            >
              {{ field }}
              <div
                v-if="sortByField === field"
                class="absolute right-px top-[13px]"
                :class="{ 'rotate-180': sortAscending }"
              >
                <NuxtIcon name="fairr:sort" />
              </div>
            </td>
          </tr>
        </thead>
        <tbody
          class="text-sm"
        >
          <tr
            v-for="(item, i) in itemsSortedSearched"
            :key="i"
          >
            <td
              v-for="field in dataFields.filter((f) => !hiddenFields.includes(f))"
              :key="i + field"
              class="border border-solid border-level-standard py-2 pl-[12px] align-middle first-of-type:pl-2 last-of-type:whitespace-pre-line last-of-type:pr-2"
            >
              <div v-if="field === 'Name'">
                {{ item['Ticker'] }}<br />
                <NuxtLink
                  v-if="item[field]"
                  :id="item['Ticker'] === 'AD:NA' ? 'walkthroughAhold' : ''"
                  :to="`/resources/companies-assessed/${item.Ticker}/supply-chain-analysis`"
                  @click="
                    pushDataLayerEvent('evConsumerCompanyClick', {
                      name: item[field],
                      sendToURL: `${baseURL}/resources/companies-assessed/${item.Ticker}/supply-chain-analysis`,
                    })
                  "
                >
                  <span class="underline underline-offset-4">
                    {{ item[field] }}
                  </span>
                </nuxtlink>
              </div>

              <div v-else-if="field === 'Market Cap' || field === 'Supplier Revenue Share'">
                {{ item[field] ? `$${Number(item[field]).toFixed(2)}bn` : 'ND' }}
              </div>

              <div v-else-if="field === 'Supplier'">
                <NuxtLink
                  v-for="(supplier, index) in item[field]"
                  :key="supplier"
                  :to="`/resources/companies-assessed/${item['Supplier Ticker']?.[index]}/protein-producer-index`"
                  class="mb-1 block underline underline-offset-1"
                  @click="
                    pushDataLayerEvent('evSupplierCompanyClick', {
                      name: supplier,
                      sendToURL: `${baseURL}/resources/companies-assessed/${item['Supplier Ticker']?.[index]}/protein-producer-index`,
                    })
                  "
                >
                  {{ supplier }}
                </NuxtLink>
              </div>

              <div v-else>
                {{ item[field] }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </UiContainer>
  </section>
</template>
