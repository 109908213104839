<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeHeaderVideo } from "~/types/contentful";
import type { TypeVimeo } from "~/types/TypeVimeo";
import nodeRenderers from "~/lib/node-renderers.js";

const props = defineProps<{
  fields: TypeHeaderVideo<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const { data: vimeo } = await useLazyFetch<TypeVimeo>(
  "https://vimeo.com/api/oembed.json?url="
  + encodeURIComponent(props.fields.videoDownload?.fields.url ?? ""),
);
</script>

<template>
  <UiContainer
    class="relative bg-dark-blue-grey-two py-4 text-white"
  >
    <template #outside>
      <NuxtImg
        v-if="props.fields.videoDownload?.fields.videoThumbnail?.fields.file?.url"
        class="absolute top-0 size-full object-cover lg:object-center"
        :src="props.fields.videoDownload?.fields.videoThumbnail?.fields.file?.url"
        :alt="props.fields.videoDownload?.fields.videoThumbnail?.fields.title"
        sizes="sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
        :height="700"
        fit="contain"
        loading="lazy"
      />
    </template>

    <HeaderBlockBreadcrumbs class="relative z-10 text-white" />

    <header class="relative z-10 grid h-[30vh] min-h-[550px] grid-cols-12">
      <div
        class="col-span-6 flex flex-col justify-center rounded-b py-4 text-white md:py-4"
      >
        <UiVideoInfo
          v-if="props.fields.videoDownload"
          :download="props.fields.videoDownload"
          :duration="vimeo?.duration"
          :show-date="true"
          class="mb-3"
        />

        <div>
          <h1>{{ props.fields.videoDownload?.fields.title }}</h1>
          <p
            v-if="props.fields.videoDownload?.fields.videoDescription"
            class="mt-4 pb-2 font-normal"
          >
            <RichTextRenderer
              :document="props.fields.videoDownload?.fields.videoDescription"
              :node-renderers="nodeRenderers"
            />
          </p>
          <UiDownloadLink
            v-if="props.fields.videoDownload"
            :download="props.fields.videoDownload"
            class-list="bg-light-royal-blue px-[36px] pb-[11px] pt-[9px] text-white"
            :in-video-hub="true"
          >
            Watch Now
          </UiDownloadLink>
        </div>
      </div>
      <div class="col-span-6 flex h-full items-center justify-center">
        <UiDownloadLink
          v-if="props.fields.videoDownload"
          :download="props.fields.videoDownload"
          :in-video-hub="true"
        >
          <div
            class="flex h-[74px] w-[128px] items-center justify-center rounded bg-light-royal-blue text-white"
          >
            <NuxtIcon
              name="fairr:play"
              size="38"
            />
          </div>
        </UiDownloadLink>
      </div>
    </header>
  </UiContainer>
</template>
