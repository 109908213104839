<script setup lang="ts">
const props = defineProps<{
  coverage: number | number[];
  colour: string | string[];
}>();

const coverages = computed(() => Array.isArray(props.coverage) ? props.coverage : [props.coverage]);
const colours = computed(() => Array.isArray(props.colour) ? props.colour : [props.colour]);
</script>

<template>
  <div class="flex h-3 min-w-[100px] rounded-sm bg-level-standard">
    <div
      v-for="(coverage2, index) in coverages"
      :key="index"
      class="h-full bg-ui-grey2"
      :style="{ width: `${coverage2 * 100}%`, backgroundColor: colours[index] }"
    />
  </div>
</template>
