<script setup lang="ts">
import { ScrollTrigger } from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import { gsap } from "gsap";
import type { TypeLottieScroll } from "~/types/contentful";

gsap.registerPlugin(ScrollTrigger);

const props = defineProps<{
  fields: TypeLottieScroll<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

onMounted(() => {
  // This looks like copy-pasted object spaghetti and likely needs rewriting (cast to unknown below is required to prevent type errors)

  const vars = {
    target: "#lottie-animation",
    path: props.fields.lottieJsonFile?.fields.file?.url,
    speed: "slow",
    pinnedContainer: "lottie-container",
    scrub: 1, // seconds it takes for the playhead to "catch up"
    // you can also add ANY ScrollTrigger values here too, like trigger, start, end, onEnter, onLeave, onUpdate, etc. See https://greensock.com/docs/v3/Plugins/ScrollTrigger
  };

  const playhead = { frame: 0 };
  const target = gsap.utils.toArray(vars.target)[0];
  const speeds: Record<string, string> = {
    slow: "+=4000",
    medium: "+=1000",
    fast: "+=500",
  };
  const st = {
    trigger: target,
    pin: true,
    start: "center center",
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    end: speeds[vars.speed] || "+=1000",
    endTrigger: ".banner-promo",
    scrub: 1,
  };
  const animation = lottie.loadAnimation({
    container: target as Element,
    renderer: "svg",
    loop: false,
    autoplay: false,
    path: vars.path,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });
  animation.addEventListener("DOMLoaded", function () {
    gsap.to(playhead, {
      frame: animation.totalFrames - 1,
      ease: "none",
      onUpdate: () => {
        animation.goToAndStop(playhead.frame, true);
      },
      scrollTrigger: { ...st, ...vars } as unknown as ScrollTrigger,
    });
  });
  return animation;
});
</script>

<template>
  <div class="hidden md:block">
    <div class="lottie-wrapper">
      <div class="lottie-container" />
      <div id="lottie-animation" />
    </div>
  </div>
</template>

<style land="scss" scoped>
#lottie-container {
  width: 100%;
  height: 100vh !important;
}
</style>
