<script setup lang="ts">
import salesforceCountries from "~/lib/salesforce/countries";

defineProps<{ formEndpoint: string }>();

const emit = defineEmits(["sent"]);

const pending = ref(false);

const handle = async (event: Event) => {
  pending.value = true;

  const formData = new FormData(event.target as HTMLFormElement);

  try {
    const data = await $fetch("/api/pardot/submit", {
      method: "POST",
      body: Object.fromEntries(formData.entries()),
    });

    if (data === "ok") {
      emit("sent");
    } else {
      throw new Error(data);
    }
  } catch (error) {
    alert("Something went wrong, please try again later");

    throw error;
  }

  turnstile.value?.reset();

  pending.value = false;
};

const organisationTypes = [
  "Charities & NGOs",
  "Banks & Development Finance",
  "Business & Corporations",
  "Investors & Asset Owners",
  "Media & Communications",
  "Policy & Government",
  "Service Provider",
  "Individual",
  "Other",
];

const turnstile = ref();

const turnstileToken = ref("");
</script>

<template>
  <form @submit.prevent="handle">
    <input
      type="hidden"
      name="form_endpoint"
      :value="formEndpoint"
    />
    <div class="mb-4 grid grid-cols-2 gap-4">
      <div class="col-span-2 md:col-span-1">
        <div class="form-floating">
          <input
            id="first-name-input"
            type="text"
            name="firstname"
            placeholder="First name"
            aria-label="First name"
            required
          />
          <label for="first-name-input">First name</label>
        </div>
      </div>
      <div class="col-span-2 md:col-span-1">
        <div class="form-floating">
          <input
            id="last-name-input"
            type="text"
            name="lastname"
            placeholder="Last name"
            aria-label="Last name"
            required
          />
          <label for="last-name-input">Last name</label>
        </div>
      </div>
      <div class="col-span-2 md:col-span-1">
        <div class="form-floating">
          <input
            id="email-address-input"
            type="email"
            name="email"
            placeholder="Email address"
            aria-label="Email address"
            required
          />
          <label for="email-address-input">Email address</label>
        </div>
      </div>
      <div class="col-span-2 md:col-span-1">
        <div class="form-floating">
          <input
            id="jobtitle-input"
            type="text"
            name="jobtitle"
            placeholder="Job Title"
            aria-label="Job Title"
          />
          <label for="jobtitle-input">Job Title</label>
        </div>
      </div>
      <div class="col-span-2 md:col-span-1">
        <div class="form-floating">
          <input
            id="organisation-input"
            type="text"
            name="organisation"
            placeholder="Organisation"
            aria-label="Organisation"
          />
          <label for="organisation-input">Organisation</label>
        </div>
      </div>
      <div class="col-span-2 md:col-span-1">
        <div class="form-floating">
          <select
            id="organisationtype-input"
            name="organisationtype"
            placeholder="Organisation Type"
            aria-label="Organisation Type"
          >
            <option
              value=""
              selected
            />
            <option
              v-for="organisationType in organisationTypes"
              :key="organisationType"
              :value="organisationType"
            >
              {{ organisationType }}
            </option>
          </select>
          <label for="organisationtype-input">Organisation Type</label>
        </div>
      </div>
      <div class="col-span-2 md:col-span-1">
        <div class="form-floating">
          <select
            id="country-input"
            name="country"
            placeholder="Country"
            aria-label="Country"
          >
            <option
              value=""
              selected
            />
            <option
              v-for="country in salesforceCountries"
              :key="country"
              :value="country"
            >
              {{ country }}
            </option>
          </select>
          <label for="country-input">Country</label>
        </div>
      </div>
      <div class="col-span-2 md:col-span-1">
        <NuxtTurnstile
          ref="turnstile"
          v-model="turnstileToken"
          :options="{
            appearance: 'interaction-only',
          }"
        />
      </div>
      <input
        type="hidden"
        name="receivecommunications"
        value="1"
      />
    </div>
    <ClientOnly>
      <button
        class="btn btn--icon btn--email"
        :disabled="!turnstileToken || pending"
      >
        Submit
      </button>
    </ClientOnly>
  </form>
</template>
