<script setup lang="ts">
import type { Entry, EntrySkeletonType } from "contentful";
import { FetchError } from "ofetch";
import type { AsyncDataRequestStatus } from "#app";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import { isTypeTheme, type TypeTopic } from "~/types/contentful";

const props = defineProps<{
  entry: Entry<EntrySkeletonType, "WITHOUT_UNRESOLVABLE_LINKS">;
  portalAction?: boolean;
}>();

const slots = useSlots();

const user = useUser();

const saved = ref(false);

const { data: topics, execute: executeTopics } = await useLazyFetch<
  TypeTopic<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]
>("/api/topics", {
  query: {
    themeId: props.entry.sys.id,
  },
  immediate: false,
});

watch(props.entry, () => {
  if (isTypeTheme(props.entry)) {
    executeTopics();
  }
}, { immediate: true });

const sysIds = computed(() => {
  if (isTypeTheme(props.entry) && topics.value) {
    return [props.entry.sys.id, ...topics.value.map((topic) => topic.sys.id)];
  }

  return [props.entry.sys.id];
});

watch(() => user.value?.savedContent, () => {
  saved.value = !!user.value?.savedContent?.includes(props.entry.sys.id);
}, {
  immediate: true,
});

const status = ref<AsyncDataRequestStatus>("idle");

const update = () => {
  if (status.value === "pending") return;

  status.value = "pending";

  $fetch("/api/auth/save-content", {
    method: "POST",
    body: {
      sysIds: sysIds.value,
      saved: !saved.value, // Set to the opposite
    },
  }).then((data) => {
    const contentType = `${String(props.entry.sys.contentType.sys.id[0]?.toUpperCase())}${props.entry.sys.contentType.sys.id.slice(1)}`;
    const contentName = String("title" in props.entry.fields ? props.entry.fields.title : "name" in props.entry.fields ? props.entry.fields.name : "");

    if (data === "saved") {
      if (user.value) {
        user.value.savedContent = [...(user.value.savedContent ?? []), ...sysIds.value];
      }

      pushDataLayerEvent("evSavedContent", {
        contentType, contentName, portalAction: props.portalAction,
      });
    } else {
      if (user.value) {
        user.value.savedContent = user.value.savedContent?.filter((savedSysId) => !sysIds.value.includes(savedSysId)) ?? [];
      }

      pushDataLayerEvent("evUnsavedContent", {
        contentType, contentName, portalAction: props.portalAction,
      });
    }

    status.value = "success";
  })
    .catch((error: unknown) => {
      if (error instanceof FetchError) {
        window.alert(error.data.message);
      }

      status.value = "error";
    });
};
</script>

<template>
  <slot
    v-if="!user && slots['logged-out']"
    name="logged-out"
  />
  <slot
    v-else-if="slots.default"
    :saved="saved"
    :status="status"
    :update="update"
  />
</template>
