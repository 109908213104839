<script setup lang="ts">
import { Collapse } from "vue-collapsed";
import type { Entry } from "contentful";
import {
  isTypeArticle,
  isTypeDownload,
  isTypeCompany,
  isTypeProject,
  isTypeReport,
  isTypeTheme,
  type TypeArticleSkeleton,
  type TypeCompanySkeleton,
  type TypeDownloadSkeleton,
  type TypeProjectSkeleton,
  type TypeReportSkeleton,
  type TypeThemeSkeleton,
} from "~/types/contentful";

defineProps<{
  savedItems: Entry<TypeArticleSkeleton | TypeCompanySkeleton | TypeDownloadSkeleton | TypeProjectSkeleton | TypeReportSkeleton | TypeThemeSkeleton, "WITHOUT_UNRESOLVABLE_LINKS">[];
  title: string;
  type: "bookmarks" | "companies" | "engagements" | "tools" | "articles" | "themes";
  options?: "saved" | "suggested";
}>();

const showMore = ref(false);

const handleShowMore = () => {
  showMore.value = !showMore.value;
};
</script>

<template>
  <div class="border-ui-grey2 pb-4 [&:not(:last-child)]:border-b">
    <h4 class="mb-3 text-[16px]">
      {{ title }}
    </h4>
    <div
      class="flex flex-col"
      :class="type !== 'themes' && 'gap-4'"
    >
      <template
        v-for="(savedItem, index) in savedItems"
        :key="index"
      >
        <DashboardDrawerBookmarkItem
          v-if="(isTypeArticle(savedItem)
            || isTypeReport(savedItem)
            || isTypeDownload(savedItem)
            || isTypeProject(savedItem))
            && index < 3"
          :saved-item="savedItem"
          :type="type"
          :following="options === 'saved'"
        />

        <DashboardDrawerCompanyItem
          v-if="isTypeCompany(savedItem) && index < 3"
          :saved-item="savedItem"
          :following="options === 'saved'"
        />

        <DashboardDrawerThemeItem
          v-if="
            type === 'themes'
              && isTypeTheme(savedItem)
              && savedItem.fields.title !== 'Multiple ESG Themes'
          "
          :theme="savedItem"
        />
      </template>
    </div>
    <Collapse
      :when="showMore"
      class="mt-4 flex flex-col gap-4"
    >
      <template
        v-for="(savedItem, index) in savedItems"
        :key="savedItem.sys.id"
      >
        <DashboardDrawerBookmarkItem
          v-if="(isTypeArticle(savedItem)
            || isTypeReport(savedItem)
            || isTypeDownload(savedItem)
            || isTypeProject(savedItem))
            && index >= 3"
          :saved-item="savedItem"
          :type="type"
          :following="options === 'saved'"
        />
        <DashboardDrawerCompanyItem
          v-if="isTypeCompany(savedItem) && index >= 3"
          :saved-item="savedItem"
        />
      </template>
    </Collapse>

    <DashboardDrawerShowMore
      v-if="savedItems?.length > 3 && type !== 'themes'"
      class="mt-4"
      :show-more="showMore"
      @handle-show-more="handleShowMore"
    />
  </div>
</template>
