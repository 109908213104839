<script setup lang="ts">
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import { accessLevel } from "~/lib/downloads";
import type { TypeUser } from "~/types/auth";
import type { TypeDownload } from "~/types/contentful";

const props = defineProps<{
  download: TypeDownload<"WITHOUT_UNRESOLVABLE_LINKS">;
  classList?: string;
  disablePardot?: boolean;
  disableModal?: boolean;
  inVideoHub?: boolean;
  inPortal?: boolean;
  following?: boolean;
}>();

const emit = defineEmits(["videoDownload"]);

const url = computed(() => props.download.fields.contentType === "Video" && props.inVideoHub ? "/resources/video-hub/player/" + String(props.download.fields.slug) : props.download.fields.url);

const downloadType = computed(() =>
  props.download.fields.contentType === "Video"
    ? "video"
    : props.download.fields.contentType === "Report"
      && url.value?.startsWith("https://fairr.turtl.co")
      ? "turtl"
      : "url",
);

const user = useUser();

const dataLayerEventPushed = ref(false);

const pushDataLayerEventWrapper = (
  user: TypeUser | null,
  download: TypeDownload<"WITHOUT_UNRESOLVABLE_LINKS">,
) => {
  // Don't push the same event twice
  if (dataLayerEventPushed.value) return;

  const eventKey = {
    video: "VideoDownload",
    turtl: "TurtlReport",
    url: "Download",
  }[downloadType.value];

  pushDataLayerEvent(
    `ev${accessLevel(user, download) === "pardot" ? "Guarded" : ""}${eventKey}`,
    {
      name: download.fields.title,
      url: url.value,
      project: download.fields.project?.fields.name,
      access: download.fields.access,
      portalAction: props.inPortal,
    },
  );

  if (props.inPortal) {
    pushDataLayerEvent("evPortalClick", {
      contentType: "download",
      contentName: download.fields.title,
      contentUrl: url.value,
      following: props.following,
    });
  }

  dataLayerEventPushed.value = true;
};

const openModal = ref(false);
</script>

<template>
  <LazyOtherModal
    v-if="downloadType === 'video' && !props.inVideoHub"
    v-model="openModal"
    :vimeo-video-url="url"
  />

  <LazyOtherModal
    v-else-if="downloadType === 'turtl'"
    v-model="openModal"
    class="flex items-center justify-center"
  >
    <UiContainer class="aspect-video w-[1280px] bg-white">
      <iframe :src="url" />
    </UiContainer>
  </LazyOtherModal>

  <template v-if="accessLevel(user, download) === 'full'">
    <NuxtLink
      :href="url"
      :target="inVideoHub ? '_self' : '_blank'"
      :class="classList"
      @click="
        (event) => {
          if (downloadType === 'turtl' || downloadType === 'video' && !inVideoHub) {
            event.preventDefault();

            !disableModal ? openModal = true : emit('videoDownload');
          }

          pushDataLayerEventWrapper(user, download);
        }
      "
    >
      <slot />
    </NuxtLink>
  </template>

  <FormPardot
    v-else-if="
      !disablePardot
        && accessLevel(user, download) === 'pardot'
        && download.fields.formHandlerUrl
    "
    :form-endpoint="download.fields.formHandlerUrl"
    :languages="download.fields.languages"
    :no-thanks="downloadType === 'turtl' || downloadType === 'video'"
    @success="
      () => {
        if (downloadType === 'turtl' || downloadType === 'video') {
          !disableModal ? openModal = true : emit('videoDownload');
        }

        pushDataLayerEventWrapper(user, download);
      }
    "
  >
    <template #default="{ on }">
      <a
        href=""
        :class="classList"
        v-on="on"
      >
        <slot />
      </a>
    </template>
  </FormPardot>

  <div v-else-if="accessLevel(user, download) === 'locked' || disablePardot">
    <slot name="locked">
      <slot />
    </slot>
  </div>
</template>
