<script setup lang="ts">
import { sortByKey } from "~/lib/sort";
import { updateFiltersChecked, filterArray } from "~/lib/filter";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import type { TypeChartDataLayer } from "~/types/TypeChartDataLayer";
import type { TypeCrtCompany } from "~/types/csv/TypeCrt";
import type { TypeChartCompany } from "~/types/TypeChartCompany";
import type { TypeProject } from "~/types/contentful";

const props = defineProps<{
  project: TypeProject<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
  filterPanelBlocks: string[][];
  filterFields: string[];
  filterTitles: string[];
  companies: TypeCrtCompany[];
  regions: string[];
  proteinTypes: string[];
}>();

const xValues = ["Companies", "Protein Types", "Regions"];
const yValues = [
  "Costs",
  "Heat stress",
  "Carbon Tax",
  "Feed",
  "Feed Additives",
];
const yFields = [
  { field: "Feed", colour: "#78dbaf" },
  { field: "Carbon Tax", colour: "#fd71a0" },
  { field: "Heat stress", colour: "#f2bb4a" },
];

const yFieldsWithAdditives = computed<{ field: string; colour: string }[]>(
  () => {
    if (!highSelected.value) return yFields;
    const additives = [{ field: "Feed Additives", colour: "#A471FD" }];
    return [...additives, ...yFields];
  },
);

const highSelected = computed(
  () => scenarioSelected.value === "High Climate Impact",
);

const filtersChecked = ref<{ field: string; id: string }[]>([]);

const scenarios = ["Net Zero Aligned", "BAU", "High Climate Impact"];
const scenarioFields = ["Low", "BAU", "High"];

const xSelected = ref<string>(xValues[0] ?? "");
const ySelected = ref<string>(yValues[0] ?? "");

const yIsAdditives = computed(() => ySelected.value === "Feed Additives");

const oneFieldSelected = computed(() => ySelected.value !== "Costs");

const yFieldsReordered = computed(() => {
  if (!oneFieldSelected.value) return yFieldsWithAdditives.value;
  const all = highSelected.value ? yFieldsWithAdditives.value : yFields;
  const index = all.findIndex((f) => f.field === ySelected.value);
  const fields = [...all];
  const selected = fields.splice(index, 1)[0];
  if (selected) {
    fields.unshift(selected);
  }
  return fields;
});

const keysToDisplay = computed(() => {
  return !oneFieldSelected.value
    ? yFieldsWithAdditives.value
    : yFields.filter((f) => f.field === ySelected.value);
});

const yearSelected = ref<string>("2030");

const scenarioSelected = ref<string>(scenarios[0] ?? "");
const scenarioIndex = computed(() => scenarios.indexOf(scenarioSelected.value));

const isDoubleChart = computed(() => xSelected.value !== "Companies");
const orderedBy = "Cost";

const chartCompanies = computed(() => {
  const filteredCompanies = filterArray(props.companies, filtersChecked.value);

  const array = filteredCompanies.map((company) => {
    const values = companyValues(company);
    const c: TypeChartCompany = {
      csv: company,
      name: company["Company Abbreviation"],
      values,
      median: calculateMedian(values),
      valueHigh: selectedHighest(values),
    };
    return { ...c, ...company };
  });
  return sortByKey(
    array,
    oneFieldSelected.value ? "valueHigh" : "median",
  ).reverse();
});

const calculateMedian = (values: NonNullable<TypeChartCompany["values"]>) => {
  return values[values.length - 1]?.to ?? 0 - Math.abs(values[0]?.from ?? 0);
};

const selectedHighest = (values: NonNullable<TypeChartCompany["values"]>) => {
  if (!oneFieldSelected.value) return 0;
  return values.find((value) => value.field === ySelected.value)?.to;
};

const companyValues = (
  company: TypeCrtCompany,
  doAverage?: boolean,
  items?: TypeCrtCompany[],
  year?: string,
) => {
  const scenario = scenarioFields[scenarioIndex.value] ?? "";

  const values = yFieldsReordered.value.map((item) => {
    const theField = `${item.field} ${
      year ?? yearSelected.value
    } ${scenario}` as keyof TypeCrtCompany;
    const value
      = doAverage && items
        ? getAverage(items, theField)
        : parseInt(company[theField]);
    return { field: item.field, value, colour: item.colour };
  });

  return valuesFromTo(values);
};

const valuesFromTo = (
  values: { field: string; value: number; colour: string }[],
) => {
  const array = [];

  for (let i = 0; i < values.length; i++) {
    const value = values[i]?.value;
    if (value === undefined) continue;
    let from = 0;
    let to = 0;
    if (i === 0 && value < 0) {
      from = value;
    } else if (i === 0) {
      to = value;
    }
    if (i > 0) {
      from = array[i - 1]?.to ?? 0;
      to = (array[i - 1]?.to ?? 0) + value;
    }

    const colour
      = !oneFieldSelected.value || ySelected.value === values[i]?.field
        ? values[i]?.colour
        : "transparent";

    array.push({
      field: values[i]?.field ?? "",
      colour: colour ?? "",
      from,
      to,
    });
  }

  return array;
};

const regionValues2030 = computed(() =>
  averageItems(props.regions, "Region", "2030"),
);
const regionValues2050 = computed(() =>
  averageItems(props.regions, "Region", "2050"),
);
const proteinValues2030 = computed(() =>
  averageItems(props.proteinTypes, "Main Protein Category", "2030"),
);
const proteinValues2050 = computed(() =>
  averageItems(props.proteinTypes, "Main Protein Category", "2050"),
);

const averageItems = (items: string[], field: string, year: string) => {
  const array = items.map((name: string) => {
    const filteredCompanies = props.companies.filter(
      (c) => c[field as keyof TypeCrtCompany] === name,
    );
    return averageItem(filteredCompanies, name, year);
  }).filter((item): item is NonNullable<typeof item> => !!item);
  return sortByKey(
    array,
    oneFieldSelected.value ? "valueHigh" : "median",
  ).reverse();
};

const averageItem = (items: TypeCrtCompany[], name: string, year: string) => {
  const first = items[0];
  if (!first) return null;
  const values = companyValues(first, true, items, year);
  return {
    csv: first,
    name,
    values,
    median: calculateMedian(values),
    valueHigh: selectedHighest(values),
  };
};

const getAverage = (
  array: TypeCrtCompany[],
  property: keyof TypeCrtCompany,
) => {
  return (
    array.reduce(
      (total: number, next: TypeCrtCompany) => total + parseInt(next[property]),
      0,
    ) / array.length
  );
};

const chartItems = computed(() => {
  if (xSelected.value === xValues[0]) return chartCompanies.value;
  if (xSelected.value === xValues[1]) return proteinValues2030.value;
  if (xSelected.value === xValues[2]) return regionValues2030.value;
  return chartCompanies.value;
});

const chart2Items = computed(() => {
  if (xSelected.value === xValues[1]) return proteinValues2050.value;
  if (xSelected.value === xValues[2]) return regionValues2050.value;
  return chartCompanies.value;
});

const onFilterChange = (checked: boolean, id: string, index: number) => {
  const field = props.filterFields[index] ?? "";

  updateFiltersChecked(filtersChecked.value, field, checked, id);

  if (checked) {
    chartDataLayer.filter = {
      group: field,
      value: id,
    };
    pushDataLayerEvent("evChartFilter", chartDataLayer);
  }
};

const onClearClick = () => {
  filtersChecked.value = [];
};

const chartDataLayer = inject<TypeChartDataLayer>(
  "chartDataLayer",
  {} as TypeChartDataLayer,
);
</script>

<template>
  <UiContainer
    class="tool-chart tool-chart--breakdown"
    :class="{ highSelected }"
  >
    <ChartControls
      :filter-panel-blocks="filterPanelBlocks"
      :filter-titles="filterTitles"
      :x-values="xValues"
      :y-values="yValues"
      :scenarios="scenarios"
      :disable-years="isDoubleChart"
      :year="yearSelected"
      :scenario="scenarioSelected"
      :x-selected="xSelected"
      :y-selected="ySelected"
      :is-bar-chart="true"
      :is-double-chart="isDoubleChart"
      :control-pillars="false"
      @on-x-select="
        xSelected = $event;
        chartDataLayer.xValue = $event;
        pushDataLayerEvent('evChartXSelected', chartDataLayer);
      "
      @on-y-select="
        ySelected = $event;
        chartDataLayer.yValue = $event;
        pushDataLayerEvent('evChartYSelected', chartDataLayer);
      "
      @on-filter-change="onFilterChange"
      @on-clear-click="onClearClick"
      @on-scenario="
        if (highSelected && yIsAdditives) ySelected = yValues[0] ?? '';
        scenarioSelected = $event;
        chartDataLayer.scenario = $event;
        pushDataLayerEvent('evChartScenarioSelected', chartDataLayer);
      "
      @on-year="
        yearSelected = $event;
        chartDataLayer.year = $event;
        pushDataLayerEvent('evChartYearSelected', chartDataLayer);
      "
      @on-title-update="chartDataLayer.title = $event"
    />

    <div class="ms-2 flex ps-4 md:ms-1 lg:ps-0 2xl:ms-0">
      <ChartChart
        class="mb-5"
        :project="project"
        :items="chartItems"
        :title-left="ySelected + ' (% Change from 2020)'"
        :min-score="-10"
        :max-score="70"
        :scenario="scenarioSelected"
        :ordered-by="isDoubleChart ? '' : orderedBy"
        :is-double-chart="isDoubleChart"
        :year="isDoubleChart ? '2030' : ''"
        :is-bar-chart="true"
        :one-field-only="oneFieldSelected"
      />
      <ChartChart
        v-if="isDoubleChart && chart2Items"
        class="mb-5"
        :project="project"
        :items="chart2Items"
        title-left=""
        :min-score="-10"
        :max-score="70"
        :scenario="scenarioSelected"
        :ordered-by="orderedBy"
        year="2050"
        :is-bar-chart="true"
        :one-field-only="oneFieldSelected"
      />
    </div>

    <div class="tool-chart__keys mx-auto flex items-center px-2 text-xs">
      <div
        v-for="(item, k) in keysToDisplay"
        :key="k"
        class="p-2 font-medium tracking-widest"
      >
        <span>{{ item.field }}</span>
        <span
          class="key-color ms-2"
          :style="{ background: item.colour }"
        />
      </div>
      <div class="p-2 font-medium tracking-widest">
        <span>Total Cost Change</span>
        <span class="key-color key-color--total ms-2" />
      </div>
    </div>
  </UiContainer>
</template>

<style lang="scss" scoped>
.tool-chart {
  width: 100%;

  &--breakdown:deep(.crt-dropdowns) {
    .dropdown:last-of-type .dropdown__item:not([data-index="0"]) {
      padding-left: 20px !important;

      &::before {
        content: "";
        width: 1px;

        @apply bg-ui-grey2;

        height: 100%;
        top: 0;
        left: 10px;
        position: absolute;
        display: block;
      }
    }
  }

  &:not(.highSelected):deep(.crt-dropdowns) {
    .dropdown:last-of-type .dropdown__item[data-index="4"] {
      @apply text-ui-grey2;

      pointer-events: none;
    }
  }
}

.crt-dropdowns {
  @apply max-xl:-mt-6;
}
</style>
