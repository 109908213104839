<script setup lang="ts">
import type { Entry } from "contentful";
import { isTypeTopic, type TypeThemeSkeleton, type TypeTopicSkeleton } from "~/types/contentful";

defineProps<{
  topic: Entry<TypeThemeSkeleton | TypeTopicSkeleton, "WITHOUT_UNRESOLVABLE_LINKS">;
  colour: "navy" | "white";
  badgeColour?: string;
  variation?: string;
  portalAction?: boolean;
}>();

const icon = computed(() => {
  return ["fairr:cross", "fairr:tick"];
});
</script>

<template>
  <UiSaveContent
    :entry="topic"
    :portal-action="portalAction"
  >
    <template #default="{ saved, status, update }">
      <TextBadge
        class="cursor-pointer"
        :class="{
          'animate-opacity-pulse': status === 'pending',
          [`badge--${variation}`]: variation,
          'border border-ui-grey': saved && variation === 'drawer',
          'border border-ui-grey bg-transparent': !saved && variation === 'drawer',
        }"
        :colour="badgeColour ? badgeColour : isTypeTopic(topic) ? topic.fields.theme?.fields.colour : 'transparent'"
        @click="update"
      >
        {{ topic.fields.title }}
        <NuxtIcon
          :name="saved ? (icon[1] ?? '') : (icon[0] ?? '')"
          size="18"
          class="cursor-pointer"
          :class="{
            'animate-opacity-pulse': status === 'pending',
            'rotate-45': !saved,
            [`text-${colour}`]: colour,
          }"
        />
      </TextBadge>
    </template>
    <template #logged-out>
      <TextBadge
        :class="{
          [`badge--${variation}`]: variation,
        }"
        :colour="badgeColour ? badgeColour : isTypeTopic(topic) ? topic.fields.theme?.fields.colour : 'transparent'"
      >
        {{ topic.fields.title }}
      </TextBadge>
    </template>
  </UiSaveContent>
</template>
