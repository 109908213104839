<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeModuleInlinePages, TypePage } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

const props = defineProps<{
  fields: TypeModuleInlinePages<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"];
}>();

// We load our pages in from Contentful, as the original page request won't have fetched data deep enough
const { data: pages } = await useFetch<
  TypePage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]
>("/api/pages", {
  query: { pageIds: props.fields.pages?.map((page) => page?.sys.id) },
  transform: (data) =>
    useCircularProtect(data).sort(
      (a, b) =>
        (props.fields.pages?.findIndex((page) => page?.sys.id === a.sys.id)
          ?? 0)
        - (props.fields.pages?.findIndex((page) => page?.sys.id === b.sys.id)
          ?? 0),
    ),
});

const subPageSlug = computed(() =>
  useRoute()
    .fullPath.replace(/\?[^#]*/, "")
    .replace(props.fields.basePath ?? "", ""),
);

const activeItemIndex = computed(() => {
  const index = pages.value?.findIndex((page) =>
    subPageSlug.value.startsWith(page.fields.slug ?? ""),
  );

  return !index || index < 0 ? 0 : index;
});

const pagesWithBasePath = computed(
  () =>
    pages.value?.map((page) => ({
      fields: {
        title: page.fields.title,
        slug: (props.fields.basePath ?? "") + (page.fields.slug ?? ""),
        access: page.fields.access,
      },
    })) ?? [],
);
</script>

<template>
  <!-- This must be client-only as URL #hashes are not sent to the server (for rendering purposes) -->
  <ClientOnly>
    <NavigationSubpage
      :fields="{
        items: pagesWithBasePath,
        tag: fields.tag,
        badge: fields.badge,
        depth: fields.depth,
        activeItemIndex,
      }"
    />
  </ClientOnly>
  <template v-for="(page, index) in pages">
    <div
      v-if="page && index === activeItemIndex"
      :id="page?.fields.slug"
      :key="page?.sys.id"
      class="inline-page"
    >
      <MembersOnly
        :level="page.fields.access || ['Public']"
        :show-content="true"
        :show-push="true"
      >
        <RichTextRenderer
          :document="page.fields.content"
          :node-renderers="nodeRenderers"
        />
      </MembersOnly>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.inline-page {
  // Extend the classes used in the UiContainer component (as RichTextRenderer doesn't support wrapping child nodes)
  :deep(> :not(section, .inline-page)) {
    @apply container h-full px-3 md:px-4 lg:px-5;
  }
}
</style>
