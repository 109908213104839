<script setup lang="ts">
import type { TypeSaveableContent } from "~/types/TypeSaveableContent";

const props = defineProps<{
  entry: TypeSaveableContent;
  type: "save" | "follow";
  portalAction?: boolean;
}>();

const icon = computed(() =>
  props.type === "save" ? ["fairr:save", "fairr:saved"] : ["fairr:follow", "fairr:following"],
);
</script>

<template>
  <UiSaveContent
    :entry="entry"
    :portal-action="portalAction"
  >
    <template #default="{ saved, status, update }">
      <button
        class="btn print:hidden"
        :class="
          ($attrs.class,
           {
             'animate-opacity-pulse': status === 'pending',
           })
        "
        @click="update"
      >
        <NuxtIcon
          :name="saved ? (icon[1] ?? '') : (icon[0] ?? '')"
          size="20"
          class="mr-2 text-white"
        />
        <template v-if="type === 'save'">
          Save<span v-if="saved">d</span>
        </template>
        <template v-if="type === 'follow'">
          <span v-if="!saved">Follow</span> <span v-else>Following</span>
        </template>
      </button>
    </template>
    <template #logged-out>
      <NuxtLink
        to="/login"
        class="btn group relative print:hidden"
      >
        <NuxtIcon
          :name="icon[0] ?? ''"
          size="20"
          class="mr-2 text-white"
        />
        <template v-if="type === 'save'">
          Save
        </template>
        <template v-else>
          Follow
        </template>
        <UiTooltip text="Log in to be able to follow" />
      </NuxtLink>
    </template>
  </UiSaveContent>
</template>
