<script setup lang="ts">
import type { Entry } from "contentful";
import type { TypeReport, TypeReportSkeleton } from "~/types/contentful";

defineProps<{
  report: Entry<TypeReportSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
  fields: TypeReport<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const contentConfig = useContentConfig();
</script>

<template>
  <UiContainer
    class="relative bg-dark-blue-grey-two py-4 text-white lg:border-l-[16px] lg:border-l-sapphire lg:before:absolute lg:before:left-2 lg:before:top-0 lg:before:z-10 lg:before:block lg:before:h-full lg:before:w-1 lg:before:bg-sapphire lg:before:content-['']"
  >
    <template #outside>
      <NuxtImg
        v-if="contentConfig?.reportHeaderBackgroundImage?.fields.file?.url"
        class="absolute top-0 size-full object-cover object-[70%] max-md:object-[55%] lg:object-center"
        :src="contentConfig.reportHeaderBackgroundImage.fields.file.url"
        :alt="contentConfig.reportHeaderBackgroundImage.fields.title"
        sizes="sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
        :height="600"
        fit="cover"
        loading="lazy"
      />
    </template>
    <HeaderBlockBreadcrumbs class="relative text-white" />
    <header
      class="relative flex-wrap items-center justify-between py-4 lg:flex lg:min-h-[450px] lg:py-5"
    >
      <div class="w-full items-center lg:flex lg:w-6/12 xl:w-7/12">
        <div class="header-text mb-4 md:mb-5 lg:mb-0">
          <h1>{{ fields.title }}</h1>
          <div class="h2 mt-3">
            {{ fields.subtitle }}
          </div>

          <div class="mt-4">
            <UiSaveButtonPrimary
              :entry="report"
              type="save"
            />
          </div>
        </div>
      </div>
      <div class="w-full lg:flex lg:w-5/12 xl:w-4/12">
        <HeaderBlockInfo
          v-if="fields.downloads"
          :downloads="
            fields.downloads?.filter(
              (download): download is NonNullable<typeof download> => !!download,
            )
          "
          :image="fields.image"
        />
      </div>
    </header>
  </UiContainer>
</template>
