<script setup lang="ts">
import { isTypePage } from "~/types/contentful";

const contentConfig = useContentConfig();

const page = useLoadedContent();

const metaTitle = computed(() => {
  let title;

  if (page.value) {
    if (isTypePage(page.value)) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      title = page.value.fields.metaTitle || page.value.fields.title;
    }
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return title || page.value?.fields.slug || "";
});

const metaTitleBase = computed(() =>
  page.value
  && ((isTypePage(page.value) && !page.value.fields.hideMetaTitleBase)
    || !isTypePage(page.value))
    ? contentConfig.value?.metaTitleBase
    : "",
);

useHead({
  title: metaTitle,
  ...(metaTitleBase.value ? {} : { titleTemplate: null }),
});
</script>

<template>
  <div>
    <Container
      class="md:block"
    >
      <header
        class="!z-[100] flex items-center justify-between pt-2 max-lg:pb-2 lg:pt-[20px]"
      >
        <div class="flex items-center">
          <NuxtLink
            to="/"
            class="w-[88px]"
            aria-label="Home"
          >
            <NuxtIcon
              name="fairr:logo"
              size="33"
              class="text-dark-blue-grey-two"
            />
          </NuxtLink>
          <div class="ml-4 leading-5">
            <div class="text-xs max-md:-mt-px lg:mb-[-3px] lg:mt-[2px]">
              Member AUM
            </div>
            <span class="text-lg font-medium lg:text-xl">${{ contentConfig?.aum }} trillion</span>
          </div>
        </div>
      </header>
    </Container>
    <main>
      <slot />
    </main>
  </div>
</template>
