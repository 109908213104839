<script setup lang="ts">
import type { EntrySys } from "contentful";

const props = defineProps<{
  sys: EntrySys;
}>();

const { data: navItems } = await useFetch("/api/nav", {
  query: { id: props.sys.id },
});
</script>

<template>
  <UiContainer class="py-6 max-md:hidden">
    <nav class="grid grid-cols-4 gap-4">
      <template
        v-for="(groupOrLink, index) in navItems"
        :key="index"
      >
        <div
          v-if="'links' in groupOrLink"
          class="flex flex-col gap-3"
        >
          <span
            class="mb-2 text-sm font-medium uppercase text-dark-blue-grey-two/50"
          >{{ groupOrLink.title }}</span>
          <NuxtLink
            v-for="(item, index2) in groupOrLink.links"
            :key="index2"
            :to="item.link"
            class="font-semibold"
          >
            {{ item.label }}
          </NuxtLink>
        </div>
        <NuxtLink
          v-else-if="'link' in groupOrLink"
          :to="groupOrLink.link"
        >
          {{ groupOrLink.label }}
        </NuxtLink>
      </template>
    </nav>
  </UiContainer>
</template>
